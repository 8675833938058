import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Slices/authSlice";
import entryReducer from "./Slices/entrySlice";
import graphsReducer from "./Slices/graphConfSlice";
import teamsReducer from "./Slices/teamsSlice";
import workconfReducer from "./Slices/workconfSlice";
import { clearState, loadState, saveState } from "./storageServices";
// import invitationsReducer from "./Slices/invitationsSlice";

const saveStateMiddleware = (storeAPI) => (next) => (action) => {
  let result = next(action);

  if (action.type === "AUTH_LOGOUT") {
    clearState();
  } else {
    saveState(storeAPI.getState());
  }

  return result;
};

const persistedState = loadState() || {};

export const store = configureStore({
  reducer: {
    auth: authReducer,
    entries: entryReducer,
    graphs: graphsReducer,
    teams: teamsReducer,
    workConf: workconfReducer,
    // invitations: invitationsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(saveStateMiddleware),

  preloadedState: persistedState, // Utilisez l'état persisté pour initialiser le store
});

// store.subscribe(() => {
//   saveState(store.getState()); // Sauvegardez l'état actuel chaque fois qu'il change
// });
