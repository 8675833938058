import React, { Suspense } from "react";
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";
import { useEffect } from "react";
import axios from "axios";

export default function Home() {
  // const https = require("https");

  const firebaseConfig = {
    // apiKey: "VOTRE_API_KEY",
    // authDomain: "VOTRE_AUTH_DOMAIN",
    // projectId: "VOTRE_PROJECT_ID",
    // storageBucket: "VOTRE_STORAGE_BUCKET",
    databaseURL: "VOTRE_DATABASE_URL",

    apiKey: "AIzaSyDDm0k_97AqDVTKmRhT52USR3aT7LLtRrQ",
    authDomain: "gameslib.firebaseapp.com",
    projectId: "gameslib",
    storageBucket: "gameslib.appspot.com",
    messagingSenderId: "114170331925",
    appId: "1:114170331925:web:30adf668c2d5cdb4695f1c",
  };

  // Fonction pour ajouter des données à Firestore
  // function addToFirestore(collectionName, documentId, data) {
  //   const url = `https://firestore.googleapis.com/v1/projects/${firebaseConfig.projectId}/databases/(default)/documents/${collectionName}/${documentId}`;
  //   const jsonData = JSON.stringify({ fields: data });

  //   const options = {
  //     hostname: "firestore.googleapis.com",
  //     port: 443,
  //     path: url,
  //     method: "PATCH",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Content-Length": jsonData.length,
  //       Authorization: `Bearer ${firebaseConfig.apiKey}`,
  //     },
  //   };

  //   const req = https.request(options, (res) => {
  //     res.on("data", (d) => {
  //       process.stdout.write(d);
  //     });
  //   });

  //   req.on("error", (error) => {
  //     console.error(error);
  //   });

  //   req.write(jsonData);
  //   req.end();
  // }

  // Exemple d'utilisation : ajouter des données à Firestore
  // const collectionName = "exampleCollection";
  // const documentId = "exampleDocumentId";
  // const dataToAdd = {
  //   field1: "value1",
  //   field2: "value2",
  // };

  // Fonction pour récupérer des données depuis Firestore
  async function getFirestoreData(collectionName, documentId) {
    const url = `https://firestore.googleapis.com/v1/projects/${firebaseConfig.projectId}/databases/(default)/documents/${collectionName}/${documentId}`;

    const options = {
      hostname: "firestore.googleapis.com",
      port: 443,
      path: url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${firebaseConfig.apiKey}`,
      },
    };

    // const req = https.request(options, (res) => {
    //   let data = "";

    //   res.on("data", (chunk) => {
    //     data += chunk;
    //   });

    //   res.on("end", () => {
    //     const jsonData = JSON.parse(data);
    //     console.log("Données récupérées :", jsonData.fields); // Les données récupérées sont dans jsonData.fields
    //   });
    // });

    try {
      const req2 = axios.get(url, {
        headers: {
          Authorization: `Bearer ${firebaseConfig.apiKey}`,
        },
      });
      console.log(req2);
    } catch (error) {
      console.log(error);
    }

    // req.on("error", (error) => {
    //   console.error(error);
    // });

    // req.end();
  }

  console.log("test");
  console.log("cqcq");

  // Exemple d'utilisation : récupérer des données depuis Firestore
  const collectionName = "exampleCollection";
  const documentId = "exampleDocumentId";

  useEffect(() => {
    // console.log("useEffect");
    // const resp = getFirestoreData(collectionName, documentId);
    // addToFirestore(collectionName, documentId, dataToAdd);
  }, []);

  return (
    <section className="fullPage" id="home">
      <Suspense fallback={Loading}>
        <header>
          <Navbar />
        </header>
        <main>HOME</main>
      </Suspense>
    </section>
  );
}
