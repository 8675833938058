import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React from "react";
import "../style/StatsCards.scss";

export default function StatsCard({ cat, title, sub, value }) {
  const header = <span className="Card_Category">{cat}</span>;
  const footer = (
    <>
      <p className="m-0">{title}</p>
    </>
  );
  return (
    <div>
      <Card
        title={value}
        // subTitle={sub}
        footer={footer}
        header={header}
        className="md:w-25rem stats-cards"
      >
        <p className="m-0">{sub}</p>
      </Card>
    </div>
  );
}
