import React, { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useRoutes,
} from "react-router-dom";

import "./style/App.scss";
import "./style/Global.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";

// Vos composants de page
import Home from "./pages/Home";
import Login from "./pages/Auth/Login";
import Dashboard from "./pages/Boards/Dashboard";
import Register from "./pages/Auth/Register";
import PrivateRoute from "./services/privateRoute";
import store from "./services/store/store";
import { checkUserAuthentication } from "./services/store/Slices/authSlice";
import Teamsboard from "./pages/Boards/Teamsboard";
import Profile from "./pages/Profile";
import Works from "./pages/Works";
import Administration from "./pages/Administration";
import AdminRoute from "./services/adminRoute";
import Entries from "./pages/Entries";
import useAxiosInterceptor from "./services/interceptor";
import Teams from "./pages/Teams";

function App() {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(checkUserAuthentication());
  // }, [dispatch]);

  useAxiosInterceptor();

  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        {/* <Route path="/auth" element={<Login />} /> */}
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/dashboard"
          element={<PrivateRoute component={Dashboard} />}
        />
        <Route path="/team" element={<PrivateRoute component={Teamsboard} />} />
        <Route path="/profile" element={<PrivateRoute component={Profile} />} />
        <Route path="/works" element={<PrivateRoute component={Works} />} />
        <Route path="/entries" element={<PrivateRoute component={Entries} />} />
        <Route path="/teams" element={<PrivateRoute component={Teams} />} />

        {/* ZONE ADMIN A SECURISER */}
        {
          <Route
            path="/administration"
            element={<AdminRoute component={Administration} />}
          />
        }
      </Routes>
    </Router>
  );
}
export default App;
