import React, { Suspense, useEffect, useState } from "react";
import Loading from "../components/Loading";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";
import { ConvertTime } from "../services/utils";
import EntryTable from "../components/EntryTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import EntryTableV2 from "../components/EntryTable_v2";
import EntryTableV3 from "../components/EntryTable_v3";
import EntryTableV4 from "../components/EntryTable_v4";
import { EntryTableV5 } from "../components/EntryTable_v5";
import EntryTableV6 from "../components/EntryTable_v6";

export default function Entries() {
  const user = useSelector((state) => state.auth.user); // Utilisez le sélecteur approprié pour extraire l'utilisateur
  const entries = useSelector((state) => state.entries.entries);

  useEffect(() => {}, [entries]);

  const [products, setProducts] = useState([]);

  return (
    <section className="fullPage" id="app">
      <Suspense fallback={Loading}>
        <header>
          <Navbar />
        </header>
        <main>
          <section className="header">
            <h1>Mes Heures</h1>
            <p>
              {user.firstName} {user.lastName}, voici vos heures :
            </p>
          </section>

          <section>
            <EntryTableV6 />
          </section>
        </main>
      </Suspense>
    </section>
  );
}

/* {entries.map((entry) => (
              <div key={entry._id + entry.title}>
                {entry.teamId} - {entry.date} - {entry.description} -{" "}
                {entry.isReclaimed.toString()} - {entry.minutes}
                <button onClick={ModifyEntry}>modify</button>
                <button>delete</button>
              </div>
            ))} */

//   <div key={entry._id + entry.title}>
//     {entry.teamId} - {entry.date} - {entry.description} -{" "}
//     {entry.isReclaimed.toString()} - {entry.minutes}
//     <form onSubmit={() => SaveModifications(entry._id)}>
//       <label>
//         _Id:
//         <input
//           type="text"
//           value={entry._id}
//           //   onChange={(e) => setTeamId(e.target.value)}
//           readOnly
//         />
//       </label>
//       <label>
//         TeamId:
//         <input
//           type="text"
//           value={entry.teamId}
//           //   onChange={(e) => setTeamId(e.target.value)}
//         />
//       </label>
//       <label>
//         Date et Heure Début: {entry.date}
//         <input
//           type="datetime-local"
//           //   value={ConvertTime(entry.date)}
//           value={entry.date}
//           //   onChange={(e) => setTeamId(e.target.value)}
//         />
//       </label>
//       <label>
//         Date et Heure FIN:
//         <input
//           type="datetime-local"
//           //   value={dateEnd}
//           //   onChange={(e) => setDateEnd(e.target.value)}
//         />
//       </label>
//       <label>
//         Description:
//         <input
//           type="text"
//           value={entry.description}
//           //   onChange={(e) => setDescription(e.target.value)}
//           required
//         />
//       </label>
//       <label>
//         Reclamed :
//         <input
//           type="checkbox"
//           value={entry.minutes}
//           //   onChange={(e) => setReclamed(!reclamed)}
//         />
//       </label>
//       <button type="submit">Enregistrer</button>
//     </form>
//     {/* <button onClick={ModifyEntry(entry._id + entry.title)}> */}
//     <button
//       onClick={() => {
//         setEntryToMod(entry);
//       }}
//     >
//       modify
//     </button>
//     <button>delete</button>
//   </div>
