import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { useDispatch, useSelector } from "react-redux";
import api from "../services/api";
import {
  AddMinutesToDate,
  CalcTimeDiff,
  FormatDateForInput,
} from "../services/utils";
import {
  modifyEntryById,
  removeEntry,
} from "../services/store/Slices/entrySlice";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
// import ReclamedEditor from "./Entries/ReclamedEditor";
// import ReclamedEditor from "./Entries/ReclamedEditor";

export default function EntryTableV4() {
  const [entries, setEntries] = useState(
    useSelector((state) => state.entries.entries)
  );
  // const [updatedEntries, setUpdatedEntries] = useState(entries);
  let updatedEntries = undefined;

  const [statuses] = useState(["INSTOCK", "LOWSTOCK", "OUTOFSTOCK"]);

  const [isEditable, setIsEditable] = useState(false);
  const [modified, setModified] = useState(false);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    // _id: entry._id,
    // teamId: entry.teamId,
    // date: FormatDateForInput(entry.date),
    // dateEnd: AddMinutesToDate(entry.date, entry.minutes),
    // description: entry.description,
    // isReclaimed: entry.isReclaimed,
    // minutes: entry.minutes,
    // archived: entry.archived ? entry.archived : null,
  });

  function ConfirmDeleteEntry() {
    console.log("ConfifmDeleteEntry activated");

    // intégrer une confirmation par modal

    DeleteEntry();
  }

  async function DeleteEntry(entry) {
    // try {
    //   const response = await api.delete(`/time-entries/${entry._id}`);
    //   console.log("response DELETE", response);
    //   dispatch(removeEntry(response.data));
    //   reintStates();
    // } catch (error) {
    //   if (error.response.data.message === "Entry not found") {
    //     alert("L'entrée n'existe pas");
    //     console.log(
    //       "Erreur dans la suppression de l'entrée : L'entrée n'existe pas",
    //       error
    //     );
    //   } else {
    //     alert("Erreur dans la suppression de l'entrée");
    //     console.log("Erreur dans la suppression de l'entrée", error);
    //   }
    // }
  }

  function reintStates() {
    setModified(false);
    setIsEditable(false);
  }

  const onRowEditComplete = async (e) => {
    const updatedEntry = e.data;
    console.log("updatedEntry", updatedEntry);
    console.log("ON DEVRAIT SAVE LA");
    try {
      //   const response = await api.patch(
      //     `/time-entries/${updatedEntry._id}`,
      //     updatedEntry
      //   );
      //   console.log("response PATCH", response);
      // Si nécessaire, mettez à jour le store Redux ou effectuez d'autres actions ici
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'entrée", error);
    }
  };

  const textEditor = (props) => {
    return (
      <InputText
        type="text"
        value={props.rowData[props.field]}
        onChange={(e) => {
          const updatedEntries = [...entries];
          updatedEntries[props.rowIndex][props.field] = e.target.value;
          setEntries(updatedEntries);
        }}
      />
    );
  };
  const minutesEditor = (props) => {
    return (
      <InputNumber
        type="text"
        value={props.rowData[props.field]}
        onChange={(e) => {
          const updatedEntries = [...entries];
          updatedEntries[props.rowIndex][props.field] = e.value; // Notez que c'est e.value pour InputNumber
          setEntries(updatedEntries);
        }}
      />
    );
  };
  const reclamedData = (props) => {
    // console.log("reclamedData props", props);
    // console.log("reclamedData props.isReclaimed", props.isReclaimed);
    // console.log("reclamedData props", props.rowData[props.field]);
    return (
      <Checkbox
        onChange={(e) => {}}
        checked={props.isReclaimed}
        disabled
      ></Checkbox>
    );
  };
  const ReclamedEditor = (props) => {
    console.log("ReclamedEditor props", props);
    let matching = updatedEntries.find(
      (element) => element._id === props.rowData._id
    );
    console.log("matching", matching);
    return (
      <Checkbox
        onChange={(e) => {
          // Initial value
          console.log("props", props);
          console.log("props.rowData.isReclaimed", props.rowData.isReclaimed);

          // Click value
          console.log("e", e);
          console.log("e.chceked", e.checked);
          matching.isReclaimed = !matching.isReclaimed;
        }}
        checked={matching.isReclaimed}
      />
    );
  };

  useEffect(() => {
    !updatedEntries ? (updatedEntries = entries) : <></>;
  }, [entries]);

  return (
    <div className="card p-fluid">
      <DataTable
        value={entries}
        editMode="row"
        dataKey="_id"
        onRowEditComplete={onRowEditComplete}
      >
        <Column field="_id" header="_Id" editor={textEditor}></Column>
        <Column field="teamId" header="TeamId" editor={textEditor}></Column>
        <Column field="date" header="Début" editor={textEditor}></Column>
        <Column
          field="minutes"
          header="Minutes"
          editor={minutesEditor}
        ></Column>
        <Column
          field="description"
          header="Description"
          editor={textEditor}
        ></Column>
        <Column
          field="isReclaimed"
          header="Reclaimed"
          editor={ReclamedEditor}
          body={reclamedData}
        ></Column>
        <Column rowEditor></Column>
      </DataTable>
    </div>
  );
}

// function ReclamedEditor(props) {
//   console.log("ReclamedEditor props", props);
//   const [tempoD, setTempoD] = useState();

//   useEffect(() => {
//     setTempoD(props.rowData.isReclaimed);
//   }, [props.rowData.isReclaimed]);

//   return (
//     <Checkbox
//       onChange={(e) => {
//         e.originalEvent.preventDefault();
//         setTempoD(e.checked);
//         // ... le reste de votre logique
//       }}
//       checked={tempoD}
//     />
//   );
// }

async function SaveModifications() {
  //     // check Si formData est égal à l'entrée
  //     if (formData === entry) {
  //       console.log("formData === entry");
  //       return;
  //     } else {
  //       // console.log("date", formData.date);
  //       // console.log("dateEnd", formData.dateEnd);
  //       const timeDiff = CalcTimeDiff(formData.date, formData.dateEnd);
  //       // console.log("timeDiff", timeDiff);
  //       // console.log("difInMinutes", difInMinutes);
  //       const dateStartUTC = new Date(formData.date).toISOString();
  //       const dateEndUTC = new Date(formData.dateEnd).toISOString();
  //       const data = { _id: entry._id };
  //       formData.teamId !== entry.teamId ? (
  //         (data.teamId = formData.teamId)
  //       ) : (
  //         <></>
  //       );
  //       formData.date !== entry.date ? (data.date = dateStartUTC) : <></>;
  //       timeDiff !== entry.minutes
  //         ? (data.minutes = timeDiff)
  //         : console.log("minutes pas modifié");
  //       formData.description !== entry.description ? (
  //         (data.description = formData.description)
  //       ) : (
  //         <></>
  //       );
  //       formData.isReclaimed !== entry.isReclaimed ? (
  //         (data.isReclaimed = formData.isReclaimed)
  //       ) : (
  //         <></>
  //       );
  //       try {
  //         const response = await api.patch(`/time-entries/${entry._id}`, data);
  //         console.log("response PATCH", response);
  //         dispatch(modifyEntryById(response.data));
  //         reintStates();
  //       } catch (error) {
  //         if (error.response.data.message === "Entry not found") {
  //           alert("L'entrée n'existe pas");
  //           console.log(
  //             "Erreur dans la modification de l'entrée : L'entrée n'existe pas",
  //             error
  //           );
  //         } else {
  //           alert("Erreur dans la suppression de l'entrée");
  //           console.log("Erreur dans la modification de l'entrée", error);
  //         }
  //       }
  //     }
}

// <Checkbox
//   onChange={(e) => {
//     console.log("e", e.checked);
//     // prevent default
//     e.originalEvent.preventDefault();
//     //   console.log("updatedEntries", updatedEntries);
//     //   const updatedEntriesTemp = [...updatedEntries];
//     //   const updatedEntry = { ...updatedEntriesTemp[props.rowIndex] }; // Créez une copie de l'élément
//     //   console.log("updatedEntry", updatedEntry);
//     //   updatedEntry.isReclaimed = e.checked; // Modifiez la copie
//     //   updatedEntriesTemp[props.rowIndex] = updatedEntry; // Remplacez l'élément dans le tableau
//     //   setUpdatedEntries(updatedEntriesTemp);
//     //   console.log("updatedEntriesTemp", updatedEntriesTemp);
//     const tempo = [...updatedEntries];
//     console.log("tempo -----------------------", tempo);
//     tempo[props.rowIndex][props.field] = e.checked;
//     console.log("tempo ----------------------- 22222222", tempo);
//     setUpdatedEntries(tempo);
//   }}
//   checked={props.rowData.isReclaimed}
// />
