import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";

const initialState = {
  allUsers: null,
  allTeams: null,
};

const AdminSlice = createSlice({
  name: "administration",
  initialState,
  reducers: {
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
    setAllTeams: (state, action) => {
      state.allTeams = action.payload;
    },
  },
});

export const { setAllUsers, setAllTeams } = AdminSlice.actions;

export default AdminSlice.reducer;
