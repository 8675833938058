import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "../style/nav.scss";
import { useNavigate } from "react-router-dom";

import { AiOutlineFieldTime } from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../services/store/Slices/authSlice";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { logout } from "../services/store/storageServices";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logOut());
    dispatch(logout());
  };

  const { isAdmin, isLoggedIn } = useSelector((state) => state.auth);


  const toast = useRef(null);

  const entriesList = [
    {
      label: "Dashboard",
      // icon: "pi pi-refresh",
      command: () => {
        navigate("/dashboard");
      },
    },
    {
      label: "Mes Heures",
      // icon: "pi pi-times",
      command: () => {
        navigate("/entries");
      },
    },
  ];

  const teamsList = [
    {
      label: "TeamsBoard",
      // icon: "pi pi-refresh",
      command: () => {
        navigate("/team");
      },
    },
    {
      label: "Mes Teams",
      // icon: "pi pi-times",
      command: () => {
        navigate("/teams");
      },
    },
    {
      label: "Configurations",
      // icon: "pi pi-times",
      command: () => {
        navigate("/works");
      },
    },
  ];

  const startContent = (
    <React.Fragment>
      <button id="homeBTN" onClick={() => navigate("/dashboard")}>
        <AiOutlineFieldTime color={"black"} size={30} />
        Heures Supp'
      </button>
      <Button
        icon="pi pi-chart-line"
        className="p-button-secondary"
        text
        size="medium"
        label="Dashboard"
        onClick={() => navigate("/dashboard")}
      />
      <Button
        className="p-button-secondary"
        icon="pi pi-clock"
        text
        size="medium"
        label="Mes Heures"
        onClick={() => navigate("/entries")}
      />
      {isAdmin ? (
        <Button
          icon="pi pi-cog"
          className="p-button-secondary"
          text
          size="medium"
          onClick={() => navigate("/administration")}
          label="Admin"
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );

  const startContentV2 = (
    <React.Fragment>
      <button id="homeBTN" onClick={() => navigate("/dashboard")}>
        <AiOutlineFieldTime color={"black"} size={30} />
        Heures Supp'
        {/* <Link to="/dashboard" id="homelink">
        </Link> */}
      </button>
      <SplitButton
        label="Heures"
        // icon="pi pi-plus"
        onClick={() => navigate("/dashboard")}
        // onClick={navToDashboard}
        model={entriesList}
        severity="secondary"
        text
      />
      <SplitButton
        label="Teams"
        // icon="pi pi-plus"
        onClick={() => navigate("/team")}
        // onClick={navToDashboard}
        model={teamsList}
        severity="secondary"
        text
      />
    </React.Fragment>
  );

  const endContent = (
    <React.Fragment>
      <Button
        icon="pi pi-user"
        className="p-button-secondary"
        text
        size="large"
        onClick={() => navigate("/profile")}
      />
      <Button
        icon="pi pi-sign-out"
        className="p-button-secondary"
        id="logoutBTN"
        text
        size="large"
        onClick={handleLogout}
      />
    </React.Fragment>
  );

  const navToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <nav id="navbar">
      {!isLoggedIn ? (
        <div className="p-toolbar p-component" id="public-nav">
          <button id="homeBTN">
            <Link to="/">
              <AiOutlineFieldTime color={"black"} size={30} />
              Heures Supp'
            </Link>
          </button>
          {/* <span className="out-sec"> */}
          <span>
            {/* <button className="out">
              <Link to="/auth">Login</Link>
            </button>
            <button className="out">
              <Link to="/register">Register</Link>
            </button>{" "} */}
            <Button
              icon="pi pi-user"
              className="p-button-secondary"
              text
              size="large"
              onClick={() => navigate("/")}
            />
          </span>
        </div>
      ) : (
        // <>
        //   <button id="homeBTN">
        //     <Link to="/dashboard">
        //       <AiOutlineFieldTime color={"black"} size={30} />
        //       Heures Supp'
        //     </Link>
        //   </button>
        //   <SplitButton
        //     label="Heures"
        //     // icon="pi pi-plus"
        //     onClick={() => navigate("/dashboard")}
        //     // onClick={navToDashboard}
        //     model={entriesList}
        //     severity="secondary"
        //     text
        //   />

        //   <button className="connected">
        //     <Link to="/dashboard">Dashboard</Link>
        //   </button>
        //   <button className="connected">
        //     <Link to="/entries">Mes Heures</Link>
        //   </button>
        //   <SplitButton
        //     label="Teams"
        //     // icon="pi pi-plus"
        //     onClick={() => navigate("/team")}
        //     // onClick={navToDashboard}
        //     model={teamsList}
        //     severity="secondary"
        //     text
        //   />
        //   <button className="connected">
        //     <Link to="/team">TeamBoard</Link>
        //   </button>
        //   <button className="connected">
        //     <Link to="/teams">Mes équipes</Link>
        //   </button>
        //   <button className="connected">
        //     <Link to="/works">Works</Link>
        //   </button>
        //   <button className="connected">
        //     <Link to="/profile">Profile</Link>
        //   </button>
        //   {isAdmin ? (
        //     <button className="connected">
        //       <Link to="/administration">Admin</Link>
        //     </button>
        //   ) : (
        //     <></>
        //   )}
        //   <button className="connected" onClick={handleLogout}>
        //     Logout
        //   </button>
        // </>
        <Toolbar start={startContent} end={endContent} />
      )}
    </nav>
  );
};

export default Navbar;
