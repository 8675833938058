import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "../../services/api";
import { addTeam, addTeamThunk } from "../../services/store/Slices/teamsSlice";
import { addWorkConf } from "../../services/store/Slices/workconfSlice";

function NewTeam() {
  const user = useSelector((state) => state.auth.user);
  const teams = useSelector((state) => state.teams.teams);

  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [managerId, setManagerId] = useState(user._id);
  const [responsablesId, setResponsablesId] = useState([]);
  const [peopleId, setPeopleId] = useState([user._id]);

  const [options, setOptions] = useState([
    // teams.managed.map((team) => {
    //   return (
    //     <option key={team._id} value={team._id}>
    //       {team.title}
    //     </option>
    //   );
    // }),
    <option key={user._id} value={user._id}>
      {user.firstName} {user.lastName}
    </option>,
  ]);

  async function handleSubmit(event) {
    event.preventDefault();
    // console.log("You clicked submit.");

    // console.log(managerId);
    // console.log(responsablesId);
    // console.log(responsablesId.length);
    // console.log(peopleId);
    // console.log(peopleId.length);

    let newTeam = {
      title: title,
      managerId: managerId,
    };
    responsablesId != [""] &&
    responsablesId != [] &&
    responsablesId.length > 0 ? (
      (newTeam.responsablesId = responsablesId)
    ) : (
      <></>
    );
    peopleId != [""] && peopleId != [] && peopleId.length > 0 ? (
      (newTeam.peopleId = peopleId)
    ) : (
      <></>
    );

    try {
      const resp = await api.post("/teams", newTeam);
      console.log(resp);
      console.log("resp teams", resp.data.team);
      dispatch(addTeamThunk(resp.data.team));
      console.log("resp conf", resp.data.conf);
      dispatch(addWorkConf(resp.data.conf));
    } catch (error) {
      console.log(error);
      alert("Error", error);
    }
  }

  function reintStates() {
    setTitle("");
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Title:
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </label>

        <label>
          ManagerId:
          {/* <input
            type="text"
            value={managerId}
            onChange={(e) => setManagerId(e.target.value)}
            required
          /> */}
          <select
            onChange={(e) => {
              console.log(e.target.value);
              setManagerId(e.target.value);
            }}
            selected={true}
            required
          >
            {options}
          </select>
        </label>
        <label>
          ResponsablesId:
          <input
            type="text"
            value={responsablesId}
            onChange={(e) => setResponsablesId(e.target.value)}
          />
        </label>
        <label>
          PeopleId:
          <input
            type="text"
            value={peopleId}
            onChange={(e) => setPeopleId(e.target.value)}
          />
        </label>
        <button type="submit">Créer Team</button>
      </form>
    </div>
  );
}

export default NewTeam;
