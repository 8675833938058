import React, { useState } from "react";
import NewTeam from "./ToolBox/NewTeam";

function TeamToolBox() {
  const [showNewTeam, setShowNewTeam] = useState(false);

  return (
    <section>
      <h2>ToolBox</h2>
      <div className="tools-bloc">
        <button
          onClick={(e) => {
            setShowNewTeam(!showNewTeam);
          }}
        >
          New Team
        </button>
      </div>
      {showNewTeam ? <NewTeam /> : <></>}
    </section>
  );
}

export default TeamToolBox;
