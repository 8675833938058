// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.authPage {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.authPage #nav-auth {
  cursor: pointer;
  margin-top: 50px;
}
.authPage #nav-auth span {
  text-decoration: underline;
}
.authPage form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 70vw;
}
.authPage form span {
  margin-bottom: 30px;
}
.authPage form span input {
  width: 70vw;
  max-width: 300px;
  min-width: 50px;
}
.authPage form button span {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/style/auth.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;AACJ;AAAI;EACE,0BAAA;AAEN;AAEE;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,mBAAA;EAEA,WAAA;AADJ;AAQI;EACE,mBAAA;AANN;AAOM;EACE,WAAA;EACA,gBAAA;EACA,eAAA;AALR;AASM;EACE,gBAAA;AAPR","sourcesContent":[".authPage {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  align-items: center;\n  justify-content: center;\n\n  #nav-auth {\n    cursor: pointer;\n    margin-top: 50px;\n    span {\n      text-decoration: underline;\n    }\n  }\n\n  form {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-items: center;\n\n    width: 70vw;\n    label {\n      // margin: 10px 0;\n    }\n    input {\n      //   margin-top: 20px;\n    }\n    span {\n      margin-bottom: 30px;\n      input {\n        width: 70vw;\n        max-width: 300px;\n        min-width: 50px;\n      }\n    }\n    button {\n      span {\n        margin-bottom: 0;\n      }\n    }\n  }\n}\n\n#loginPage {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
