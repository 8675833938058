import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

const AdminRoute = ({ component: Component }) => {
  const { user, isAdmin } = useSelector((state) => state.auth);
  // console.log("Admin Route activée");
  // console.log("isAdmin", isAdmin);
  // console.log("user", user);

  return isAdmin ? <Component /> : <Navigate to="/dashboard" replace />;
};

export default AdminRoute;
