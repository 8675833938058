import React from "react";

function DeleteHS() {
  return (
    <div>
          <p>DeleteHS</p>
          <form>

          </form>
    </div>
  );
}

export default DeleteHS;
