import React, { useState } from "react";
import { useSelector } from "react-redux";
import api from "../../services/api";

function ModifHS() {
  const user = useSelector((state) => state.auth.user); // Utilisez le sélecteur approprié pour extraire l'utilisateur
  const token = useSelector((state) => state.auth.token); // Utilisez le sélecteur approprié pour extraire l'utilisateur

  const [teamId, setTeamId] = useState(user.teamId || "");
  const [date, setDate] = useState(undefined);
  const [minutes, setMinutes] = useState(0);
  const [description, setDescription] = useState("");
  const [reclamed, setReclamed] = useState(false);
  const [dateStart, setDateStart] = useState(undefined);
  const [dateEnd, setDateEnd] = useState(undefined);

  const [entrySelected, setEntrySelected] = useState(undefined);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("NewWT handleSubmit");
    // CalcTime();
    const datetime1 = new Date(dateStart);
    const datetime2 = new Date(dateEnd);

    const differenceInMilliseconds = datetime2 - datetime1;
    const difInMinutes = differenceInMilliseconds / (1000 * 60);
    const difInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));

    const difInHoursRest = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / 1000
    );
    const minutesSupplementaires = Math.floor(difInHoursRest / 60);
    console.log(
      `La différence entre les deux dates est de ${difInHoursRest} heures et ${minutesSupplementaires} minutes.`
    );

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = {
      teamId: teamId,
      date: dateStart,
      minutes: difInMinutes,
      description: description,
      reclamed: reclamed,
    };
    try {
      const response = await api.post("/time-entries", data, {
        headers: headers,
      });
      console.log("response", response);
    } catch (error) {
      console.log("Erreur dans la création de l'entrée", error);
    }
  };
  return (
    <div>
      <div>
        {/* <input>
        </input> */}
        Liste des Heures supp de l'utilisateur : quand sélectionné, remplé le
        form en bas
      </div>
      <form onSubmit={handleSubmit}>
        <label>
          TeamId:
          <input
            type="text"
            value={teamId}
            onChange={(e) => setTeamId(e.target.value)}
            required
          />
        </label>

        <label>
          Date et Heure Début:
          <input
            type="datetime-local"
            value={dateStart}
            onChange={(e) => setDateStart(e.target.value)}
          />
        </label>
        <label>
          Date et Heure FIN:
          <input
            type="datetime-local"
            value={dateEnd}
            onChange={(e) => setDateEnd(e.target.value)}
          />
        </label>
        <label>
          Description:
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </label>
        <label>
          Reclamed :
          <input
            type="checkbox"
            value={minutes}
            onChange={(e) => setReclamed(!reclamed)}
          />
        </label>
        <button type="submit">Enregistrer</button>
      </form>
    </div>
  );
}

export default ModifHS;
