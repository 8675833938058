import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { Checkbox } from "primereact/checkbox";
import { useDispatch, useSelector } from "react-redux";
import api from "../services/api";
import {
  addEntry,
  modifyEntryById,
  removeEntry,
} from "../services/store/Slices/entrySlice";
import { Dropdown } from "primereact/dropdown";
import {
  CalcTimeDiff,
  FormatDateForInput,
  FormatDateForPrimeInput,
} from "../services/utils";
import { Calendar } from "primereact/calendar";
import "./../style/EntryTable.scss";

export default function EntryTableV6() {
  const teams = useSelector((state) => state.teams.teams);
  const originalEntries = useSelector((state) => state.entries.entries);
  const [entries, setEntries] = useState();

  const [emptyEntry, setEmptyEntry] = useState({
    _id: "",
    teamId: "",
    date: undefined,
    dateTo: undefined, // Ajoutez cette ligne
    description: "",
    minutes: 0,
    isReclaimed: false,
  });

  const dispatch = useDispatch();

  const [entryDialog, setEntryDialog] = useState(false);
  const [deleteEntryDialog, setDeleteEntryDialog] = useState(false);
  const [entry, setEntry] = useState(emptyEntry);
  const [selectedEntries, setSelectedEntries] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const toast = useRef(null);
  const dt = useRef(null);
  const [dateValidationError, setDateValidationError] = useState(false);

  const [filterReclamed, setFilterReclamed] = useState(false);
  const [filterArchived, setFilterArchived] = useState(false);

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const hideDialog = () => {
    setSubmitted(false);
    setEntryDialog(false);
  };

  const hideDeleteEntryDialog = () => {
    setDeleteEntryDialog(false);
  };

  const saveEntry = async () => {
    // console.log("saveEntry");
    setLoading1(true);
    setSubmitted(true);

    let _entries = [...entries];
    let _entry = { ...entry };

    const index = findIndexById(entry._id);
    if (
      entry.description.trim() &&
      entry.date &&
      // entry.minutes &&
      entry.teamId
    ) {
      if (new Date(entry.date) >= new Date(entry.dateTo)) {
        setDateValidationError(true);
        return;
      } else {
        setDateValidationError(false);
      }

      if (entry.date && entry.dateTo) {
        _entry.minutes = calculateMinutesDifference(entry.date, entry.dateTo);
      }

      // si c est une modif d entry et qu il est different de l entry d origine
      if (entry._id && _entries[index] !== _entry) {
        _entries[index] = _entry;

        // si oui, alors on fait le save
        // Faire le save ICI
        const saveMods = await SaveModifications();

        if (saveMods === true) {
          setEntryDialog(false);
          reintStates();
          setEntries(_entries);
        } else {
        }
      } else if (!entry._id) {
        // ca serait dans le cas ou on cree une nouvelle entrée

        // Faire le save ICI
        const saveModifs = await SaveNewEntry();

        if (saveModifs === true) {
          setEntryDialog(false);
          reintStates();
          // setEntries(_entries);
        } else {
        }

        // saveModifs === true ? reintStates() : <></>;
      } else {
        // sinon on fait rien et exit le modal
        return;
      }

      setLoading1(false);
    }
  };

  const editEntry = (entry) => {
    // console.log("editEntry entry", entry);
    setEntry({ ...entry });
    const correspondingTeam = teams.managed.find(
      (team) => team._id === entry.teamId
    );
    setSelectedTeam(correspondingTeam._id);
    setEntryDialog(true);
  };

  async function SaveNewEntry() {
    setLoading2(true);

    console.log("entry dans le new save entry", entry);

    if (
      entry.date &&
      entry.dateTo &&
      entry.minutes &&
      entry.teamId &&
      entry.description.trim()
    ) {
      const data = {
        teamId: entry.teamId,
        date: entry.date,
        minutes: entry.minutes,
        description: entry.description,
        isReclaimed: entry.isReclaimed,
      };

      try {
        const response = await api.post("/time-entries", data);
        console.log("response POST", response);
        setLoading2(false);
        dispatch(addEntry(response.data));
        const newEntry = {
          ...response.data,
          dateTo: new Date(
            new Date(response.data.date).getTime() +
              response.data.minutes * 60000
          ).toISOString(),
        };
        setEntries([...entries, newEntry]);
        // setEntries([...entries, response.data]);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Entry Created",
          life: 3000,
        });
        return true;
      } catch (error) {
        console.log("Erreur dans la création de l'entrée", error);
        setLoading2(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            "Erreur dans la création de l'entrée. \n Si le problème persiste, contactez l'administrateur",
          life: 3000,
        });
        return false;
      }
    } else {
      setLoading2(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Veuillez remplir tous les champs obligatoires",
        life: 3000,
      });
      return false;
    }
  }

  async function SaveModifications() {
    const originalEntry = originalEntries.find(
      (originalEntry) => originalEntry._id === entry._id
    );

    const data = { _id: entry._id };

    entry.teamId !== originalEntry.teamId ? (
      (data.teamId = entry.teamId)
    ) : (
      <></>
    );
    entry.date !== originalEntry.date ? (data.date = entry.date) : <></>;
    entry.minutes !== originalEntry.minutes ? (
      (data.minutes = entry.minutes)
    ) : (
      <></>
    );
    entry.description !== originalEntry.description ? (
      (data.description = entry.description)
    ) : (
      <></>
    );
    entry.isReclaimed !== originalEntry.isReclaimed ? (
      (data.isReclaimed = entry.isReclaimed)
    ) : (
      <></>
    );

    try {
      const response = await api.patch(`/time-entries/${entry._id}`, data);
      console.log("response PATCH", response);

      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Modification enregistrée",
        life: 3000,
      });

      console.log("response.data", response.data);

      const newEntry = {
        ...response.data,
        dateTo: new Date(
          new Date(response.data.date).getTime() + response.data.minutes * 60000
        ).toISOString(),
      };
      console.log("newEntry", newEntry);

      dispatch(modifyEntryById(newEntry));
      return true;
    } catch (error) {
      if (error.response.data.message === "Entry not found") {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "L'entrée n'existe pas",
          life: 3000,
        });
        console.log(
          "Erreur dans la modification de l'entrée : L'entrée n'existe pas",
          error
        );
        return false;
      } else {
        alert("Erreur dans la suppression de l'entrée");
        console.log("Erreur dans la modification de l'entrée", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Erreur dans la modification de l'entrée",
          life: 3000,
        });
        return false;
      }
    }
  }

  const confirmDeleteEntry = (entry) => {
    setEntry(entry);
    setDeleteEntryDialog(true);
    // console.log("ca delete maintenant ?");
  };

  const deleteEntry = async () => {
    // on delete ici jec rois
    // console.log("deleteEntry ???!!!");

    try {
      const response = await api.delete(`/time-entries/${entry._id}`);
      console.log("response DELETE", response);
      dispatch(removeEntry(entry));

      // reintStates();
      let _entries = entries.filter((val) => val._id !== entry._id);
      setEntries(_entries);
      setDeleteEntryDialog(false);
      setEntry(emptyEntry);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Entry Deleted",
        life: 3000,
      });
    } catch (error) {
      if (error.response.data.message === "Entry not found") {
        alert("L'entrée n'existe pas");
        console.log(
          "Erreur dans la suppression de l'entrée : L'entrée n'existe pas",
          error
        );
      } else {
        alert("Erreur dans la suppression de l'entrée");
        console.log("Erreur dans la suppression de l'entrée", error);
      }
    }
  };

  const findIndexById = (id) => {
    // console.log("findIndexById id", id);
    let index = -1;
    for (let i = 0; i < entries.length; i++) {
      if (entries[i]._id === id) {
        index = i;
        break;
      }
    }
    return index;
  };

  const calculateMinutesDifference = (startDate, endDate) => {
    // console.log("calculateMinutesDifference");
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diff = end - start;
    return Math.floor(diff / (1000 * 60));
  };

  const onInputChange = (e, name) => {
    // console.log("onInputChange");

    const val = (e.target && e.target.value) || e.value || "";
    let _entry = { ...entry };
    // console.log("name", name);
    // console.log("val", val);
    if (name === "date") {
      _entry[name] = val.toISOString();
      if (_entry.dateTo) {
        _entry.minutes = calculateMinutesDifference(
          _entry[name],
          _entry.dateTo
        );
      }
    } else if (name === "dateTo") {
      _entry[name] = val.toISOString();
      if (_entry.date) {
        _entry.minutes = calculateMinutesDifference(_entry.date, _entry[name]);
      }
    } else if (name === "minutes") {
      const startDate = new Date(_entry.date);
      const endDate = new Date(startDate.getTime() + val * 60000); // 60000 convertit les minutes en millisecondes
      _entry.dateTo = endDate.toISOString();
      _entry.minutes = val;
    } else {
      _entry[`${name}`] = val;
    }
    setEntry(_entry);
  };

  const onCheckboxChange = (e, name) => {
    // console.log("onCheckboxChange");
    const val = e.checked;
    let _entry = { ...entry };
    _entry[`${name}`] = val;
    setEntry(_entry);
  };

  const header = (
    <div className="entrytable-datatable-header flex flex-wrap gap-2 align-items-center justify-content-between">
      {/* <h2 className="m-0">Manage Entries</h2> */}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => {
            console.log("e.target.value", e.target.value);
            setGlobalFilter(e.target.value);
          }}
          placeholder="Search..."
        />
      </span>
      <span className="datatable-filters-blc">
        {/* Filters : */}
        <span>
          <span>Reclamés</span>
          <Checkbox
            checked={filterReclamed}
            onChange={(e) => {
              console.log("!filterReclamed", !filterReclamed);
              setFilterReclamed(!filterReclamed);
            }}
          />
        </span>
        <span>
          <span>Archivés</span>
          <Checkbox
            checked={filterArchived}
            onChange={(e) => {
              setFilterArchived(!filterArchived);
            }}
          />
        </span>
      </span>
      <span>
        <Button
          label="New"
          icon="pi pi-plus"
          onClick={() => {
            emptyEntry.date = FormatDateForInput(new Date());
            emptyEntry.dateTo = FormatDateForInput(new Date());
            setEntryDialog(!entryDialog);
          }}
          className="p-button-secondary"
          outlined
        />
      </span>
    </div>
  );

  const entryDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        severity="danger"
        outlined
        onClick={hideDialog}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        // onClick={() => setEntryDialog(!entryDialog)}
        onClick={async () => {
          // if (entry._id) {
          //   const save = await saveEntry();
          //   console.log("save old _id", save);
          //   save === true ? setEntryDialog(!entryDialog) : <></>;
          // } else {
          //   const save = await SaveNewEntry();
          //   console.log("save new one", save);
          //   save === true ? setEntryDialog(!entryDialog) : <></>;
          // }
          const save = await saveEntry();
          console.log("save entry", save);
        }}
        loading={loading1}
        severity="success"
      />
    </React.Fragment>
  );

  const deleteEntryDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteEntryDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteEntry}
      />
    </React.Fragment>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-file-edit"
          // text
          rounded
          outlined
          raised
          className="actions-button"
          onClick={() => editEntry(rowData)}
        />
        <Button
          icon="pi pi-trash"
          // icon="pi pi-delete-left"
          rounded
          outlined
          raised
          severity="danger"
          className="actions-button"
          onClick={() => confirmDeleteEntry(rowData)}
        />
      </React.Fragment>
    );
  };

  const handleTeamChange = (e) => {
    // console.log("handleTeamChange");
    let _entry = { ...entry };

    _entry.teamId = e.value;
    setSelectedTeam(e.value); // Mettez à jour avec l'objet complet
    setEntry(_entry);
  };

  function reintStates() {
    // console.log("reintStates");
    setEntry(emptyEntry);
    setSelectedTeam(null);
  }

  const getTeamTitleById = (teamId) => {
    // console.log("getTeamTitleById");
    const team = teams.managed.find((team) => team._id === teamId);
    return team ? team.title : teamId; // Si l'équipe n'est pas trouvée, renvoyez l'ID par défaut
  };

  useEffect(() => {
    // console.log("useEffect []");
    console.log("originalEntries", originalEntries);
    if (originalEntries && originalEntries.length > 0) {
      const updatedEntries = originalEntries.map((entry) => {
        console.log("entry", entry);
        if (entry.dateTo) {
          return entry;
        } else {
          const startDate = new Date(entry.date);
          const endDate = new Date(startDate.getTime() + entry.minutes * 60000); // 60000 convertit les minutes en millisecondes
          return { ...entry, dateTo: endDate.toISOString() };
        }
      });
      setEntries(updatedEntries);
    }
  }, []);

  useEffect(() => {
    console.log("useEffect Original Entries [originalEntries]");
    console.log("originalEntries", originalEntries);

    if (originalEntries && originalEntries.length > 0) {
      console.log("useEffect Original Entries [originalEntries].length > 0");
      const updatedEntries = originalEntries.map((entry) => {
        console.log("entry", entry);
        if (entry.dateTo) {
          return entry;
        } else {
          const startDate = new Date(entry.date);
          const endDate = new Date(startDate.getTime() + entry.minutes * 60000); // 60000 convertit les minutes en millisecondes
          return { ...entry, dateTo: endDate.toISOString() };
        }
      });
      setEntries(updatedEntries);
    }
  }, [originalEntries]);

  useEffect(() => {
    // console.log("filterReclamed", filterReclamed);
    ChangeFilter();
  }, [filterReclamed]);
  useEffect(() => {
    // console.log("filterArchived", filterArchived);
    ChangeFilter();
  }, [filterArchived]);

  function ChangeFilter() {
    if (filterReclamed && filterArchived) {
      const filteredEntries = originalEntries.filter(
        (entry) => entry.isReclaimed === true && entry.archived === true
      );
      const updatedEntries = filteredEntries.map((entry) => {
        const startDate = new Date(entry.date);
        const endDate = new Date(startDate.getTime() + entry.minutes * 60000); // 60000 convertit les minutes en millisecondes
        return { ...entry, dateTo: endDate.toISOString() };
      });
      setEntries(updatedEntries);
    } else if (filterReclamed) {
      const filteredEntries = originalEntries.filter(
        (entry) => entry.isReclaimed === true
      );
      const updatedEntries = filteredEntries.map((entry) => {
        const startDate = new Date(entry.date);
        const endDate = new Date(startDate.getTime() + entry.minutes * 60000); // 60000 convertit les minutes en millisecondes
        return { ...entry, dateTo: endDate.toISOString() };
      });
      setEntries(updatedEntries);
    } else if (filterArchived) {
      const filteredEntries = originalEntries.filter(
        (entry) => entry.archived === true
      );
      const updatedEntries = filteredEntries.map((entry) => {
        const startDate = new Date(entry.date);
        const endDate = new Date(startDate.getTime() + entry.minutes * 60000); // 60000 convertit les minutes en millisecondes
        return { ...entry, dateTo: endDate.toISOString() };
      });
      setEntries(updatedEntries);
    } else {
      const updatedEntries = originalEntries.map((entry) => {
        const startDate = new Date(entry.date);
        const endDate = new Date(startDate.getTime() + entry.minutes * 60000); // 60000 convertit les minutes en millisecondes
        return { ...entry, dateTo: endDate.toISOString() };
      });
      setEntries(updatedEntries);
    }
  }

  const DateCell = React.memo(({ date }) => {
    // console.log("DateCell");
    return FormatDateForPrimeInput(date);
  });

  return (
    <div>
      <Toast ref={toast} />
      <div className="card" id="entryTableContainer">
        <DataTable
          id="entrytable"
          ref={dt}
          value={entries}
          selection={selectedEntries}
          onSelectionChange={(e) => setSelectedEntries(e.value)}
          dataKey="_id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          globalFilter={globalFilter}
          header={header}
          resizableColumns
          // columnResizeMode="expand"
        >
          {/* <Column selectionMode="multiple" exportable={false}></Column> */}
          {/* <Column field="_id" header="ID" sortable></Column> */}

          <Column
            field="teamId"
            header="Team"
            sortable
            body={(rowData) => getTeamTitleById(rowData.teamId)}
          ></Column>
          <Column
            field="description"
            header="Description"
            sortable
            body={(rowData) => (
              <p style={{ whiteSpace: "pre-line" }}>{rowData.description} </p>
            )}
          ></Column>
          <Column
            field="date"
            header="Date"
            sortable
            body={(rowData) => <DateCell date={rowData.date} />}
          ></Column>
          <Column
            field="dateTo"
            header="Date To"
            sortable
            body={(rowData) => <DateCell date={rowData.dateTo} />}
          ></Column>
          <Column field="minutes" header="Minutes" sortable></Column>
          <Column
            field="isReclaimed"
            header="Récupérée"
            body={(rowData) => (
              <Checkbox checked={rowData.isReclaimed} readOnly />
            )}
            sortable
          ></Column>
          <Column
            field="archived"
            header="Archivée"
            body={(rowData) => <Checkbox checked={rowData.archived} readOnly />}
            sortable
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            // style={{ minWidth: "12rem" }}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={entryDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Informations"
        modal
        className="p-fluid"
        footer={entryDialogFooter}
        onHide={hideDialog}
      >
        <div className="field">
          <label htmlFor="teamId" className="font-bold">
            Équipe
          </label>
          {/* <InputText
            id="teamId"
            value={entry.teamId}
            onChange={(e) => onInputChange(e, "teamId")}
          /> */}
          <Dropdown
            id="teamId"
            value={selectedTeam}
            options={teams.managed}
            optionLabel="title"
            optionValue="_id"
            onChange={handleTeamChange}
            placeholder="Select a Team"
            className="w-full md:w-14rem"
            required
          />
        </div>
        <div className="field">
          <label htmlFor="date" className="font-bold">
            Début
          </label>
          <Calendar
            id="date"
            showTime
            hourFormat="24"
            value={new Date(entry.date)}
            onChange={(e) => onInputChange(e, "date")}
            dateFormat="mm/dd/yy"
            showIcon
            required
            severity="info"
            icon="pi pi-calendar"
          />

          {submitted && !entry.date && (
            <small className="p-error">Date is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="dateTo" className="font-bold">
            Fin
          </label>
          <Calendar
            id="dateTo"
            showTime
            hourFormat="24"
            value={entry.dateTo ? new Date(entry.dateTo) : null}
            onChange={(e) => onInputChange(e, "dateTo")}
            dateFormat="mm/dd/yy"
            showIcon
          />
          {submitted && !entry.dateTo && (
            <small className="p-error">Date To is required.</small>
          )}
          {dateValidationError && (
            <small className="p-error">Date To should be after Date.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="minutes" className="font-bold">
            Durée (minutes)
          </label>
          <InputText
            id="minutes"
            value={entry.minutes}
            onChange={(e) => onInputChange(e, "minutes")}
            required
          />
          {submitted && !entry.minutes && (
            <small className="p-error">Minutes are required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">
            Description
          </label>
          <InputText
            id="description"
            value={entry.description}
            onChange={(e) => onInputChange(e, "description")}
            required
            autoFocus
            className={classNames({
              "p-invalid": submitted && !entry.description,
            })}
          />
          {submitted && !entry.description && (
            <small className="p-error">Description is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="isReclaimed" className="font-bold">
            Récupérée ?
          </label>
          <Checkbox
            id="isReclaimed"
            checked={entry.isReclaimed}
            onChange={(e) => onCheckboxChange(e, "isReclaimed")}
          />
        </div>
      </Dialog>

      <Dialog
        visible={deleteEntryDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteEntryDialogFooter}
        onHide={hideDeleteEntryDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {entry && <span>Are you sure you want to delete this entry?</span>}
        </div>
      </Dialog>
    </div>
  );
}
