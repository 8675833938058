import React from "react";

function WorkConfCrud() {
  return (
    <section>
      <h2>WorkConfCrud</h2>
      <div>Create</div>
      <div>Read</div>
      <div>Update</div>
      <div>Delete</div>
    </section>
  );
}

export default WorkConfCrud;
