// hooks/useAxiosInterceptor.js
import { useSelector } from "react-redux";
import api from "./api";

const useAxiosInterceptor = () => {
  const { token } = useSelector((state) => state.auth); // Utilisez le sélecteur approprié pour extraire l'utilisateur

  api.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default useAxiosInterceptor;

export const GetUser = () => {
  const { user } = useSelector((state) => state.auth); // Utilisez le sélecteur approprié pour extraire l'utilisateur
  return user;
};

