import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import React, { useState } from "react";
import api from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { removeTeam } from "../services/store/Slices/teamsSlice";
import { AutoComplete } from "primereact/autocomplete";

export default function TeamTable_v2({ teams, title }) {
  const friends = useSelector((state) => state.teams.friends);
  const [_teams, _setTeams] = useState(teams);
  let emptyTeam = {
    _id: "",
    title: "",
    managerId: "",
    responsablesId: [""],
    peopleId: [""],
    pendingIds: [""],
  };
  const [_team, _setTeam] = useState(emptyTeam);
  const [teamDialog, setTeamDialog] = useState(false);
  const [deleteTeamDialog, setDeleteTeamDialog] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [filteredIds, setFilteredIds] = useState([]);

  const allPossibleTeams = Array.isArray(_teams)
    ? _teams.map((team) => ({ label: team.title, value: team._id }))
    : [];

  const searchId = (event) => {
    let filtered = allPossibleTeams.filter((id) =>
      id.toLowerCase().includes(event.query.toLowerCase())
    );
    setFilteredIds(filtered);
  };
  const dispatch = useDispatch();

  const titles = {
    manager: "Vos Teams (manager) :",
    responsables: "Vos Teams (responsable(s)) :",
    member: "Vos Teams (member) :",
  };
  function editTeam(rowData) {
    console.log("editTeam");
    console.log(rowData);
  }
  function confirmDeleteTeam(rowData) {
    console.log("confirmDeleteTeam");
    console.log(rowData);
  }
  const actionBodyTemplate = (rowData) => {
    // console.log("actionBodyTemplate");
    return (
      <React.Fragment>
        <Button
          icon="pi pi-file-edit"
          rounded
          outlined
          className="mr-2"
          //   onClick={() => editTeam(rowData)}
          onClick={() => {
            _setTeam(rowData);
            setTeamDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          //   onClick={() => confirmDeleteTeam(rowData)}
          onClick={() => setDeleteTeamDialog(true)}
        />
      </React.Fragment>
    );
  };

  const teamDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={() => setTeamDialog(false)}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={async () => {
          const save = await saveTeam();
          console.log("save entry", save);
        }}
        loading={loading1}
      />
    </React.Fragment>
  );

  const deleteTeamDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={() => setDeleteTeamDialog(false)}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={DeleteTeam}
      />
    </React.Fragment>
  );

  const pendingIdsBodyTemplate = (rowData) => {
    return <React.Fragment>{rowData.pendingIds.length}</React.Fragment>;
  };

  async function saveTeam() {
    console.log("saveTeam");
    console.log(_team);
    setTeamDialog(false);
  }

  async function DeleteTeam() {
    try {
      const response = await api.delete(`/teams/${_team._id}`);
      console.log("response DELETE", response);
      dispatch(removeTeam(response.data));
      // reintStates();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Not found"
      ) {
        alert("La team n'existe pas");
        console.log(
          "Erreur dans la suppression de la tean : tean n'existe pas",
          error
        );
      } else {
        alert("Erreur dans la suppression de la tean");
        console.log("Erreur dans la suppression de la team", error);
      }
    }
  }
  return (
    <div>
      <h3>{titles[title]}</h3>
      <DataTable value={_teams}>
        <Column field="_id" header="ID"></Column>
        <Column field="title" header="Title"></Column>
        <Column field="managerId" header="Manager ID"></Column>
        <Column field="responsablesId" header="Responsables ID"></Column>
        <Column field="peopleId" header="People ID"></Column>
        <Column
          field="pendingIds"
          header="Pending IDs"
          body={pendingIdsBodyTemplate}
        ></Column>
        {(title === "manager" || "responsables") && (
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "12rem" }}
          ></Column>
        )}
      </DataTable>

      <Dialog
        visible={teamDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Team Details"
        modal
        className="p-fluid"
        footer={teamDialogFooter}
        onHide={() => setTeamDialog(false)}
      >
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12">
            <label htmlFor="_id">ID</label>
            <InputText id="_id" value={_team._id} disabled />
          </div>
          <div className="p-field p-col-12">
            <label htmlFor="title">Title</label>
            <InputText
              id="title"
              value={_team.title}
              onChange={(e) => _setTeam({ ..._team, title: e.target.value })}
            />
          </div>
          <div className="p-field">
            <label htmlFor="managerId">Manager ID</label>
            {/* <MultiSelect
              value={_team.managerId}
              options={allPossibleIds}
              //   options={_teams.map((team) => team._id)}
              onChange={(e) => _setTeam({ ..._team, managerId: e.value })}
              placeholder="Select Manager ID"
              display="chip"
            /> */}
          </div>
          <div className="p-field">
            <label htmlFor="responsablesId">Responsables ID</label>
            <MultiSelect
              value={_team.responsablesId}
              //   options={allPossibleIds}
              options={_teams.map((team) => team.peopleId)}
              //   onChange={(e) => _setTeam({ ..._team, responsablesId: e.value })}
              onChange={(e) =>
                _setTeam({
                  ..._team,
                  responsablesId: Array.isArray(e.value) ? e.value : [],
                })
              }
              placeholder="Select Responsables"
              display="chip"
            />
            <AutoComplete
              value={_team.responsablesId}
              suggestions={_teams.map((team) => team.peopleId)}
              completeMethod={(e) => searchId(e)}
              multiple={true}
              onChange={(e) => _setTeam({ ..._team, responsablesId: e.value })}
              placeholder="Select or Add Responsables"
            />
          </div>
          <div className="p-field">
            <label htmlFor="peopleId">People ID</label>
            <MultiSelect
              value={_team.peopleId}
              //   options={allPossibleIds}
              options={_teams.map((team) => team._id)}
              onChange={(e) => _setTeam({ ..._team, peopleId: e.value })}
              placeholder="Select People"
              display="chip"
            />
          </div>
          {/* <div className="p-field p-col-12">
            <label htmlFor="peopleId">People ID</label>
            <MultiSelect
              value={_team.peopleId}
              options={_teams.map((team) => team._id)}
              onChange={(e) => _setTeam({ ..._team, peopleId: e.value })}
            />
          </div> */}
          {/* <div className="p-field p-col-12">
            <label htmlFor="pendingIds">Pending IDs</label>
            <MultiSelect
              value={_team.pendingIds}
              options={_teams.map((team) => team._id)}
              onChange={(e) => _setTeam({ ..._team, pendingIds: e.value })}
            />
          </div> */}
        </div>
      </Dialog>

      <Dialog
        visible={deleteTeamDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteTeamDialogFooter}
        onHide={() => setDeleteTeamDialog(false)}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {_team && <span>Are you sure you want to delete this team?</span>}
        </div>
      </Dialog>
    </div>
  );
}
