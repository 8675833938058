import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "../services/api";
import {
  deleteWorkConf,
  modifyWorkConf,
} from "../services/store/Slices/workconfSlice";
import { FormatDateForInput } from "../services/utils";

function WorkTable({ work }) {
  const user = useSelector((state) => state.auth.user);

  const [isEditable, setIsEditable] = useState(false);
  const [modified, setModified] = useState(false);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    _id: work._id,
    userId: work.userId,
    teamIds: work.teamIds,
    title: work.title,
    weekHours: work.weekHours,
    daysWeek: work.daysWeek,
    data: work.data || "",
    options: work.options || "",
    dateFrom: FormatDateForInput(work.dateFrom),
    dateTo: work.dateTo || "",
  });

  const toggleEditable = () => {
    // si editable active, alors on delete les modifications avant de set en false
    if (isEditable) {
      setFormData({
        _id: work._id,
        userId: work.userId,
        teamIds: work.teamIds,
        title: work.title,
        weekHours: work.weekHours,
        daysWeek: work.daysWeek,
        data: work.data || "",
        options: work.options || "",
        dateFrom: FormatDateForInput(work.dateFrom),
        dateTo: FormatDateForInput(work.dateFrom) || "",
      });
      setModified(false);
    }
    setIsEditable(!isEditable);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log("name", name);
    console.log("value", value);
    console.log("type", type);
    console.log("checked", checked);

    // Mettez à jour formData en fonction du type de champ de formulaire
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    // Si formData est égal à l'entrée, alors setModified à false
    formData === work ? setModified(false) : setModified(true);
  };

  function ConfirmDeleteWorkConf() {
    console.log("ConfifmDeleteWorkConf activated");

    // intégrer une confirmation par modal

    DeleteWorkConf();
  }

  async function DeleteWorkConf() {
    try {
      const response = await api.delete(`/work-conf/${work._id}`);
      console.log("response DELETE", response);
      dispatch(deleteWorkConf(response.data));
      // reintStates();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Not found"
      ) {
        alert("La WorkConf n'existe pas");
        console.log(
          "Erreur dans la suppression de la tean : tean n'existe pas",
          error
        );
      } else {
        alert("Erreur dans la suppression de la tean");
        console.log("Erreur dans la suppression de la WorkConf", error);
      }
    }
  }

  async function SaveModifications() {
    console.log("You clicked submit.");
    console.log("formData", formData);
    try {
      const resp = await api.patch(`/work-conf/${work._id}`, formData);
      console.log("res", resp);
      dispatch(modifyWorkConf(resp.data));
      reintStates();
    } catch (error) {
      console.log(error);
      alert("Error", error);
    }
  }

  function reintStates() {
    setModified(false);
    setIsEditable(false);
  }

  return (
    <div>
      <form key={formData._id}>
        <label>
          Title:
          <input
            type="text"
            value={formData.title}
            onChange={handleInputChange}
            readOnly={!isEditable}
            disabled={!isEditable}
            required
          />
        </label>

        <label>
          teamIds:
          {/* <input
            type="text"
            value={teamIds}
            onChange={(e) => setManagerId(e.target.value)}
            required
          /> */}
          {/* <select
            onChange={handleInputChange}
            selected={true}
            readOnly={!isEditable}
            disabled={!isEditable}
            required
          >
            {options}
          </select> */}
          {formData.teamIds}
        </label>
        <label>
          weekHours:
          <input
            type="text"
            value={formData.weekHours}
            name="weekHours"
            onChange={handleInputChange}
            readOnly={!isEditable}
            disabled={!isEditable}
          />
        </label>
        <label>
          daysWeek:
          <input
            type="text"
            value={formData.daysWeek}
            onChange={handleInputChange}
            readOnly={!isEditable}
            disabled={!isEditable}
            name="daysWeek"
          />
        </label>
        <label>
          data:
          <input
            type="text"
            value={formData.data}
            onChange={handleInputChange}
            readOnly={!isEditable}
            disabled={!isEditable}
            name="data"
          />
        </label>
        <label>
          options:
          <input
            type="text"
            value={formData.options}
            onChange={handleInputChange}
            readOnly={!isEditable}
            disabled={!isEditable}
            name="options"
          />
        </label>
        <label>
          dateFrom:
          <input
            type="datetime-local"
            value={formData.dateFrom}
            onChange={handleInputChange}
            readOnly={!isEditable}
            disabled={!isEditable}
            name="dateFrom"
          />
        </label>
        <label>
          dateTo:
          <input
            type="datetime-local"
            value={formData.dateTo}
            onChange={handleInputChange}
            readOnly={!isEditable}
            disabled={!isEditable}
            name="dateTo"
          />
        </label>
        {/* <button type="submit">Créer WorkConf</button> */}
      </form>
      {modified ? (
        <button onClick={SaveModifications}>Enregistrer</button>
      ) : (
        <></>
      )}
      <button onClick={toggleEditable}>
        {isEditable ? "Annuler Édition" : "Mode Édition"}
      </button>
      {/* <button onClick={toggleEditable}>Save</button> */}
      {/* <button onClick={ConfirmDeleteWorkConf}>Delete</button> */}
      <br /> <br />
    </div>
  );
}

export default WorkTable;
