import React, { Suspense, useState } from "react";
import Navbar from "../../components/Navbar";
import Loading from "../../components/Loading";
import { useSelector } from "react-redux";
import TeamToolBox from "../../components/TeamToolBox";
import NewTeam from "../../components/ToolBox/NewTeam";

export default function Teamsboard() {
  const { teams } = useSelector((state) => state.teams); // Utilisez le sélecteur approprié pour extraire l'utilisateur
  const entries = useSelector((state) => state.entries.entries);
  const [showNewTeam, setShowNewTeam] = useState(false);

  // console.log("User", user);
  // console.log("Entries", entries);
  // console.log(teams);
  console.log("entries", entries);
  console.log("teams", teams);

  return (
    <section className="fullPage" id="app">
      <Suspense fallback={Loading}>
        <header>
          <Navbar />
        </header>
        <main>
          <h1>Teamsboard</h1>
          <section>
            Panneau des actions : nouvelle team / modify team / Archive team
          </section>
          <section>
            <h2>ToolBox</h2>
            <div className="tools-bloc">
              <button
                onClick={(e) => {
                  setShowNewTeam(!showNewTeam);
                }}
              >
                New Team
              </button>
            </div>
            {showNewTeam ? <NewTeam /> : <></>}
          </section>

        
          <section className="header">
            <h2>Stats générales de la team sélectionné</h2>
          </section>
          <section className="toolbox tb-teams">
            <h2>Toolbox for teams</h2>
            <TeamToolBox />
          </section>
          <section className="keyStats ks-teams">
            <h2>Stats clés</h2>
          </section>
          <section className="HSbloc hs_teams">
            <h2>Hs by teams</h2>
          </section>
          <section className="EditTeam">
            <h2>Edit teams bloc</h2>
          </section>
        </main>
      </Suspense>
    </section>
  );
}
