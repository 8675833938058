// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#profile-page {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}
#profile-page section form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
#profile-page section form div {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: center;
  flex-wrap: nowrap;
  margin: 15px 0;
}
#profile-page section form div input {
  width: 60vw;
  min-width: 300px;
  max-width: 800px;
}
#profile-page section form .change-pswd-blc {
  display: flex;
  flex-direction: row;
}
#profile-page section form .change-pswd-blc label {
  margin-right: 10px;
}
#profile-page section form .change-pswd-blc div {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/style/Profile.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;EACA,qBAAA;EACA,mBAAA;EACA,2BAAA;AACF;AACI;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,mBAAA;AACN;AAAM;EACE,aAAA;EACA,sBAAA;EACA,oBAAA;EACA,qBAAA;EACA,iBAAA;EAEA,cAAA;AACR;AACQ;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;AACV;AAEM;EACE,aAAA;EACA,mBAAA;AAAR;AAEQ;EACE,kBAAA;AAAV;AAGQ;EACE,SAAA;AADV","sourcesContent":["#profile-page {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  align-content: center;\n  align-items: center;\n  justify-content: flex-start;\n  section {\n    form {\n      display: flex;\n      flex-direction: column;\n      flex-wrap: nowrap;\n      align-items: center;\n      div {\n        display: flex;\n        flex-direction: column;\n        align-items: stretch;\n        align-content: center;\n        flex-wrap: nowrap;\n\n        margin: 15px 0;\n\n        input {\n          width: 60vw;\n          min-width: 300px;\n          max-width: 800px;\n        }\n      }\n      .change-pswd-blc {\n        display: flex;\n        flex-direction: row;\n\n        label {\n          margin-right: 10px;\n        }\n\n        div {\n          margin: 0;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
