export function CalcTimeDiff(start, end) {
  const datetime1 = new Date(start);
  const datetime2 = new Date(end);

  const differenceInMilliseconds = datetime2 - datetime1;
  const difInMinutes = differenceInMilliseconds / (1000 * 60);
  const difInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));

  const difInHoursRest = Math.floor(
    (differenceInMilliseconds % (1000 * 60 * 60)) / 1000
  );
  const minutesSupplementaires = Math.floor(difInHoursRest / 60);
  // console.log(
  //   `La différence entre les deux dates est de ${differenceInHours} heures et ${minutesSupplementaires} minutes.`
  // );

  return difInMinutes;
}

export function ConvertTime(targetDate) {
  console.log("ConvertTime targetDate", targetDate);
  const date = new Date(targetDate);

  const initialDate = date.toISOString().substring(0, 10);
  console.log("ConvertTime initialDate", initialDate);
  return initialDate;
}

export function FormatDateForInput(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export function FormatDateForPrimeInput(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois sont indexés à partir de 0
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} - ${hours}:${minutes}`;
}

export function AddMinutesToDate(dateString, minutes) {
  // Convertir la chaîne de date en objet Date
  const date = new Date(dateString);

  // Ajouter la durée en millisecondes
  date.setMinutes(date.getMinutes() + minutes);

  // Convertir en chaîne de date locale
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois sont indexés de 0 à 11
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hour}:${minute}`;
}

// export function ConvertTime(targetDate) {
//   const date = new Date(targetDate);
//   const initialDate = date.toISOString().substring(0, 10);
//   return initialDate;
// }
