import React, { Suspense } from "react";
import Loading from "../components/Loading";
import Navbar from "../components/Navbar";
import api from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { setAllTeams, setAllUsers } from "../services/store/Slices/adminSlice";
import { useEffect } from "react";
import UserCrud from "../components/Admin/UserCrud";
import TeamCrud from "../components/Admin/TeamCrud";
import EntryCrud from "../components/Admin/EntryCrud";
import WorkConfCrud from "../components/Admin/WorkConfCrud";
import GraphConfCrud from "../components/Admin/GraphConfCrud";
import InvitationCrud from "../components/Admin/InvitationCrud";
import NotificationCrud from "../components/Admin/NotificationCrud";

function Administration() {
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.auth); // Utilisez le sélecteur approprié pour extraire l'utilisateur

  async function getAllUsers() {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const allUsers = await api.get("/users/all", { headers: headers });
      console.log("allUsers", allUsers.data.users);
      dispatch(setAllUsers(allUsers.data.users));
    } catch (error) {
      console.log("Erreur dans la récupération des utilisateurs", error);
    }
  }

  async function getAllTeams() {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const allTeams = await api.get("/teams/all", { headers: headers });
      console.log("allTeams", allTeams.data.teams);
      dispatch(setAllTeams(allTeams.data.teams));
    } catch (error) {
      console.log("Erreur dans la récupération des équipes", error);
    }
  }

  useEffect(() => {
    getAllUsers();
    getAllTeams();
  }, []);

  return (
    <section className="fullPage" id="app">
      <Suspense fallback={Loading}>
        <header>
          <Navbar />
        </header>
        <main>
          <h1>Administration</h1>
          <div>
            {/* <h1>Profil de l'utilisateur</h1> */}
            {/* <p>Nom d'utilisateur : {user.username}</p> */}
            {/* <p>Email : {user.email}</p> */}
            {/* <p>{entries}</p> */}
            {/* Affichez d'autres informations de l'utilisateur ici */}
            <section>
              <h2>Users</h2>
              <UserCrud />
            </section>
            <section>
              <h2>Teams</h2>
              <TeamCrud />
            </section>
            <section>
              <h2> Time Entries</h2>
              <EntryCrud />
            </section>
            <section>
              <h2>WorkConfiguration</h2>
              <WorkConfCrud />
            </section>
            <section>
              <h2>GraphConfiguration</h2>
              <GraphConfCrud />
            </section>
            <section>
              <h2>Invitations</h2>
              <InvitationCrud />
            </section>
            <section>
              <h2>Notifications</h2>
              <NotificationCrud />
            </section>
          </div>
        </main>
      </Suspense>
    </section>
  );
}

export default Administration;
