import React from "react";

function UserCrud() {
  return (
    <section>
      <h2>UserCrud</h2>
      <div>Create</div>
      <div>Read</div>
      <div>Update</div>
      <div>Delete</div>
    </section>
  );
}

export default UserCrud;
