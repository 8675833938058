import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "../services/api";
import { removeTeam } from "../services/store/Slices/teamsSlice";

function TeamTable({ team }) {
  const user = useSelector((state) => state.auth.user);

  const [isEditable, setIsEditable] = useState(false);
  const [modified, setModified] = useState(false);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    _id: team._id,
    title: team.title,
    managerId: team.managerId,
    responsablesId: team.responsablesId,
    peopleId: team.peopleId,
    pendingIds: team.pendingIds,
  });

  const [options, setOptions] = useState([
    // teams.managed.map((team) => {
    //   return (
    //     <option key={team._id} value={team._id}>
    //       {team.title}
    //     </option>
    //   );
    // }),
    <option key={user._id} value={user._id}>
      {user.firstName} {user.lastName}
    </option>,
  ]);

  const toggleEditable = () => {
    // si editable active, alors on delete les modifications avant de set en false
    if (isEditable) {
      setFormData({
        _id: team._id,
        title: team.title,
        managerId: team.managerId,
        responsablesId: team.responsablesId,
        peopleId: team.peopleId,
        pendingIds: team.pendingIds,
      });
      setModified(false);
    }
    setIsEditable(!isEditable);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log("name", name);
    console.log("value", value);
    console.log("type", type);
    console.log("checked", checked);

    // Mettez à jour formData en fonction du type de champ de formulaire
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    // Si formData est égal à l'entrée, alors setModified à false
    formData === team ? setModified(false) : setModified(true);
  };

  function ConfirmDeleteTeam() {
    console.log("ConfifmDeleteTeam activated");

    // intégrer une confirmation par modal

    DeleteTeam();
  }

  async function DeleteTeam() {
    try {
      const response = await api.delete(`/teams/${team._id}`);
      console.log("response DELETE", response);
      dispatch(removeTeam(response.data));
      // reintStates();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Not found"
      ) {
        alert("La team n'existe pas");
        console.log(
          "Erreur dans la suppression de la tean : tean n'existe pas",
          error
        );
      } else {
        alert("Erreur dans la suppression de la tean");
        console.log("Erreur dans la suppression de la team", error);
      }
    }
  }

  async function SaveModifications() {
    console.log("You clicked submit.");

    console.log("formData", formData);

    try {
      const resp = await api.patch("/teams", formData);
      console.log("res", resp);
      // dispatch(modifyTeam(resp.data));
    } catch (error) {
      console.log(error);
      alert("Error", error);
    }
  }

  function reintStates() {
    setModified(false);
    setIsEditable(false);
  }

  return (
    <div>
      <form key={formData._id}>
        <label>
          Title:
          <input
            type="text"
            value={formData.title}
            onChange={handleInputChange}
            readOnly={!isEditable}
            disabled={!isEditable}
            required
          />
        </label>

        <label>
          ManagerId:
          {/* <input
            type="text"
            value={managerId}
            onChange={(e) => setManagerId(e.target.value)}
            required
          /> */}
          <select
            onChange={handleInputChange}
            selected={true}
            readOnly={!isEditable}
            disabled={!isEditable}
            required
          >
            {options}
          </select>
        </label>
        <label>
          ResponsablesId:
          <input
            type="text"
            value={formData.responsablesId}
            onChange={handleInputChange}
            readOnly={!isEditable}
            disabled={!isEditable}
          />
        </label>
        <label>
          PeopleId:
          <input
            type="text"
            value={formData.peopleId}
            onChange={handleInputChange}
            readOnly={!isEditable}
            disabled={!isEditable}
          />
        </label>
        {/* <button type="submit">Créer Team</button> */}
        {modified ? (
          <button onClick={SaveModifications}>Enregistrer</button>
        ) : (
          <></>
        )}
      </form>
      <button onClick={toggleEditable}>
        {isEditable ? "Annuler Édition" : "Mode Édition"}
      </button>
      {/* <button onClick={toggleEditable}>Save</button> */}
      <button onClick={ConfirmDeleteTeam}>Delete</button>
      <br /> <br />
    </div>
  );
}

export default TeamTable;
