// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entrytable-datatable-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.entrytable-datatable-header h4 {
  width: 100%;
}
.entrytable-datatable-header .datatable-filters-blc span {
  margin: 0 20px;
}
#entryTableContainer {
  display: flex;
  justify-content: center;
}
#entryTableContainer #entrytable {
  width: 90vw;
  max-width: 2000px;
  min-width: 800px;
  border: none;
}
#entryTableContainer #entrytable .p-datatable-header {
  border: none;
  background-color: #fafafa;
}
#entryTableContainer #entrytable .p-datatable-header .entrytable-datatable-header {
  justify-content: space-between;
}
#entryTableContainer #entrytable .p-datatable-wrapper {
  border: 1px solid #dee2e6;
}

.actions-button:last-child {
  margin-left: 8px;
}

.p-dialog-content .field {
  margin-top: 20px;
}
.p-dialog-content .field #isReclaimed {
  margin-left: 30px;
}

.field #date button,
.field #dateTo button {
  background-color: #c9c9c9;
  border-color: #c0c0c0;
}`, "",{"version":3,"sources":["webpack://./src/style/EntryTable.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,6BAAA;EACA,mBAAA;AACF;AACE;EACE,WAAA;AACJ;AAGI;EACE,cAAA;AADN;AASA;EACE,aAAA;EACA,uBAAA;AAPF;AASE;EACE,WAAA;EACA,iBAAA;EACA,gBAAA;EAEA,YAAA;AARJ;AAWI;EACE,YAAA;EAEA,yBAAA;AAVN;AAYM;EACE,8BAAA;AAVR;AAcI;EACE,yBAAA;AAZN;;AAiBA;EACE,gBAAA;AAdF;;AAkBE;EACE,gBAAA;AAfJ;AAgBI;EACE,iBAAA;AAdN;;AAmBA;;EAEE,yBAAA;EACA,qBAAA;AAhBF","sourcesContent":[".entrytable-datatable-header {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-around;\n  align-items: center;\n\n  h4 {\n    width: 100%;\n  }\n\n  .datatable-filters-blc {\n    span {\n      margin: 0 20px;\n      div {\n        // padding-left: 5px;\n      }\n    }\n  }\n}\n\n#entryTableContainer {\n  display: flex;\n  justify-content: center;\n\n  #entrytable {\n    width: 90vw;\n    max-width: 2000px;\n    min-width: 800px;\n    // border: 1px solid #dee2e6;\n    border: none;\n    // border-radius: 10px;\n\n    .p-datatable-header {\n      border: none;\n      //   border-bottom: 1px solid #dee2e6;\n      background-color: #fafafa;\n      // background-color: white;\n      .entrytable-datatable-header {\n        justify-content: space-between;\n      }\n    }\n\n    .p-datatable-wrapper {\n      border: 1px solid #dee2e6;\n    }\n  }\n}\n\n.actions-button:last-child {\n  margin-left: 8px;\n}\n\n.p-dialog-content {\n  .field {\n    margin-top: 20px;\n    #isReclaimed {\n      margin-left: 30px;\n    }\n  }\n}\n\n.field #date button,\n.field #dateTo button {\n  background-color: #c9c9c9;\n  border-color: #c0c0c0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
