import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
// import { ProductService } from "./service/ProductService";

export default function EntryTableV3() {
  const [products, setProducts] = useState([
    {
      id: "1000",
      code: "f230fh0g3",
      name: "Bamboo Watch",
      description: "Product Description",
      image: "bamboo-watch.jpg",
      price: 65,
      category: "Accessories",
      quantity: 24,
      inventoryStatus: "INSTOCK",
      rating: 5,
    },
  ]);
  const [statuses] = useState(["INSTOCK", "LOWSTOCK", "OUTOFSTOCK"]);

  useEffect(() => {
    // ProductService.getProductsMini().then((data) => setProducts(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSeverity = (value) => {
    switch (value) {
      case "INSTOCK":
        return "success";

      case "LOWSTOCK":
        return "warning";

      case "OUTOFSTOCK":
        return "danger";

      default:
        return null;
    }
  };

  const onRowEditComplete = (e) => {
    let _products = [...products];
    console.log(e);
    console.log(products);
    let { newData, index } = e;

    _products[index] = newData;

    setProducts(_products);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        // onChange={(e) => options.editorCallback(e.target.value)}
        onChange={(e) => {
          console.log(e.target.value);
          console.log(options.value);
          console.log(options);
          options.editorCallback(e.target.value);
        }}
      />
    );
  };

  const statusEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Select a Status"
        itemTemplate={(option) => {
          return <Tag value={option} severity={getSeverity(option)}></Tag>;
        }}
      />
    );
  };

  const priceEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={(e) => options.editorCallback(e.value)}
        mode="currency"
        currency="USD"
        locale="en-US"
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.inventoryStatus}
        severity={getSeverity(rowData.inventoryStatus)}
      ></Tag>
    );
  };

  const priceBodyTemplate = (rowData) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(rowData.price);
  };

  return (
    <div className="card p-fluid">
      <DataTable
        value={products}
        editMode="row"
        dataKey="id"
        onRowEditComplete={onRowEditComplete}
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column
          field="code"
          header="Code"
          editor={(options) => textEditor(options)}
          style={{ width: "20%" }}
        ></Column>
        <Column
          field="name"
          header="Name"
          editor={(options) => textEditor(options)}
          style={{ width: "20%" }}
        ></Column>
        <Column
          field="inventoryStatus"
          header="Status"
          body={statusBodyTemplate}
          editor={(options) => statusEditor(options)}
          style={{ width: "20%" }}
        ></Column>
        <Column
          field="price"
          header="Price"
          body={priceBodyTemplate}
          editor={(options) => priceEditor(options)}
          style={{ width: "20%" }}
        ></Column>
        <Column
          rowEditor
          headerStyle={{ width: "10%", minWidth: "8rem" }}
          bodyStyle={{ textAlign: "center" }}
        ></Column>
        {/* <Column
          field="userId"
          header="User ID"
          sortable
          // style={{ width: "25%" }}
        ></Column>
        <Column
          field="teamId"
          header="Team ID"
          sortable
          // style={{ width: "25%" }}
        >
          caca
        </Column>
        <Column
          field="date"
          header="Date"
          sortable
          // style={{ width: "25%" }}
        ></Column>
        <Column
          field="minutes"
          header="Durée (minutes)"
          sortable
          // style={{ width: "25%" }}
        ></Column>
        <Column
          field="description"
          header="Description"
          sortable
          // style={{ width: "25%" }}
        ></Column>
        <Column
          field="isReclaimed"
          header="is Reclaimed"
          sortable
          // style={{ width: "25%" }}
        ></Column>
        <Column
          field="reclaimedDate"
          header="Reclaimed Date"
          sortable
          // style={{ width: "25%" }}
        ></Column>
        <Column
          field="createdAt"
          header="CreatedAt"
          sortable
          // style={{ width: "25%" }}
        ></Column>
        <Column
          field="archived"
          header="Archived"
          sortable
          // style={{ width: "25%" }}
        ></Column> */}
      </DataTable>
    </div>
  );
}
