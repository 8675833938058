import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";

const initialState = {
  workConf: [null],
};

const workconfSlice = createSlice({
  name: "workConf",
  initialState,
  reducers: {
    setWorkConf: (state, action) => {
      state.workConf = action.payload;
    },
    addWorkConf: (state, action) => {
      state.workConf.push(action.payload);
    },
    modifyWorkConf: (state, action) => {
      const index = state.workConf.findIndex(
        (workConf) => workConf._id === action.payload._id
      );
      state.workConf[index] = action.payload;
    },
    deleteWorkConf: (state, action) => {
      state.workConf = state.workConf.filter(
        (workConf) => workConf._id !== action.payload
      );
    },
  },
});

export const { setWorkConf, addWorkConf, modifyWorkConf, deleteWorkConf } =
  workconfSlice.actions;

export default workconfSlice.reducer;
