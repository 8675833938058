import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { GetUser } from "../../interceptor";

export const addTeamThunk = createAsyncThunk(
  "teams/addTeamThunk",
  async (team, { getState, dispatch }) => {
    const user = getState().auth.user;

    // Votre logique de vérification
    if (team.managerId === user._id) {
      // Dispatch l'action addTeam avec les données nécessaires
      dispatch(addToManaged(team));
    }
    // ... Ajoutez d'autres logiques si nécessaire

    if (team.responsablesId.includes(user._id)) {
      dispatch(addToResponsables(team));
    }

    if (team.peopleId.includes(user._id)) {
      dispatch(addToMember(team));
    }
  }
);

const initialState = {
  teams: {
    managed: [],
    responsables: [],
    member: [],
  },
  friends: [],
};

const teamsSlice = createSlice(
  {
    name: "teams",
    initialState,
    reducers: {
      setTeams: (state, action) => {
        state.teams = action.payload;
      },
      addTeam: (state, action) => {
        console.log(action.payload);
        // const globalState = state; // Ici, state est l'état global
        // console.log(globalState);

        const globalState = action.thunkAPI.getState(); // Ici, globalState est l'état global
        const user = globalState.auth.user; // Accéder à l'état user du slice auth

        // const user = globalState.auth.user;
        // console.log(user);

        // if (action.payload.managerId === user._id) {
        //   let teamManagerExists = false;
        //   state.teams.managed.map((team) => {
        //     if (team._id === action.payload._id) {
        //       console.log("teamManagerExists");
        //       teamManagerExists = true;
        //     }
        //   });
        //   console.log("teamManagerExists", teamManagerExists);
        //   !teamManagerExists ? state.teams.managed.push(action.payload) : <></>;
        // }

        // if (action.payload.responsablesId.includes(user._id)) {
        //   let teamResponsableExists = false;
        //   state.teams.responsables.map((team) => {
        //     if (team._id === action.payload._id) {
        //       console.log("teamResponsableExists");
        //       teamResponsableExists = true;
        //     }
        //   });
        //   console.log("teamResponsableExists", teamResponsableExists);
        //   !teamResponsableExists ? (
        //     state.teams.responsables.push(action.payload)
        //   ) : (
        //     <></>
        //   );
        // }
        // if (action.payload.peopleId.includes(user._id)) {
        //   let teamMemberExists = false;
        //   state.teams.member.map((team) => {
        //     if (team._id === action.payload._id) {
        //       console.log("teamMemberExists");
        //       teamMemberExists = true;
        //     }
        //   });
        //   console.log("teamMemberExists", teamMemberExists);
        //   !teamMemberExists ? state.teams.member.push(action.payload) : <></>;
        // }
      },
      addToManaged: (state, action) => {
        let teamManagerExists = false;
        state.teams.managed.map((team) => {
          if (team._id === action.payload._id) {
            console.log("teamManagerExists");
            teamManagerExists = true;
          }
        });
        console.log("teamManagerExists", teamManagerExists);
        !teamManagerExists ? state.teams.managed.push(action.payload) : <></>;
      },
      addToResponsables: (state, action) => {
        let teamResponsableExists = false;
        state.teams.responsables.map((team) => {
          if (team._id === action.payload._id) {
            console.log("teamResponsableExists");
            teamResponsableExists = true;
          }
        });
        console.log("teamResponsableExists", teamResponsableExists);
        !teamResponsableExists ? (
          state.teams.responsables.push(action.payload)
        ) : (
          <></>
        );
      },
      addToMember: (state, action) => {
        let teamMemberExists = false;
        state.teams.member.map((team) => {
          if (team._id === action.payload._id) {
            console.log("teamMemberExists");
            teamMemberExists = true;
          }
        });
        console.log("teamMemberExists", teamMemberExists);
        !teamMemberExists ? state.teams.member.push(action.payload) : <></>;
      },
      removeTeam: (state, action) => {
        console.log(action.payload);

        state.teams.managed = state.teams.managed.filter(
          (team) => team._id !== action.payload.deleted._id
        );

        state.teams.responsables = state.teams.responsables.filter(
          (team) => team._id !== action.payload.deleted._id
        );

        state.teams.member = state.teams.member.filter(
          (team) => team._id !== action.payload.deleted._id
        );
      },
      setFriends: (state, action) => {
        state.friends = action.payload;
      },
    },
  }
  // {
  //   name: "friends",
  //   initialState,
  //   reducers: {
  //     setFriends: (state, action) => {
  //       state.friends = action.payload;
  //     },
  //     // addTeam: (state, action) => {
  //     //   console.log(action.payload);
  //     //   // const globalState = state; // Ici, state est l'état global
  //     //   // console.log(globalState);

  //     //   const globalState = action.thunkAPI.getState(); // Ici, globalState est l'état global
  //     //   const user = globalState.auth.user; // Accéder à l'état user du slice auth

  //     //   // const user = globalState.auth.user;
  //     //   // console.log(user);

  //     //   // if (action.payload.managerId === user._id) {
  //     //   //   let teamManagerExists = false;
  //     //   //   state.teams.managed.map((team) => {
  //     //   //     if (team._id === action.payload._id) {
  //     //   //       console.log("teamManagerExists");
  //     //   //       teamManagerExists = true;
  //     //   //     }
  //     //   //   });
  //     //   //   console.log("teamManagerExists", teamManagerExists);
  //     //   //   !teamManagerExists ? state.teams.managed.push(action.payload) : <></>;
  //     //   // }

  //     //   // if (action.payload.responsablesId.includes(user._id)) {
  //     //   //   let teamResponsableExists = false;
  //     //   //   state.teams.responsables.map((team) => {
  //     //   //     if (team._id === action.payload._id) {
  //     //   //       console.log("teamResponsableExists");
  //     //   //       teamResponsableExists = true;
  //     //   //     }
  //     //   //   });
  //     //   //   console.log("teamResponsableExists", teamResponsableExists);
  //     //   //   !teamResponsableExists ? (
  //     //   //     state.teams.responsables.push(action.payload)
  //     //   //   ) : (
  //     //   //     <></>
  //     //   //   );
  //     //   // }
  //     //   // if (action.payload.peopleId.includes(user._id)) {
  //     //   //   let teamMemberExists = false;
  //     //   //   state.teams.member.map((team) => {
  //     //   //     if (team._id === action.payload._id) {
  //     //   //       console.log("teamMemberExists");
  //     //   //       teamMemberExists = true;
  //     //   //     }
  //     //   //   });
  //     //   //   console.log("teamMemberExists", teamMemberExists);
  //     //   //   !teamMemberExists ? state.teams.member.push(action.payload) : <></>;
  //     //   // }
  //     // },
  //     // addToManaged: (state, action) => {
  //     //   let teamManagerExists = false;
  //     //   state.teams.managed.map((team) => {
  //     //     if (team._id === action.payload._id) {
  //     //       console.log("teamManagerExists");
  //     //       teamManagerExists = true;
  //     //     }
  //     //   });
  //     //   console.log("teamManagerExists", teamManagerExists);
  //     //   !teamManagerExists ? state.teams.managed.push(action.payload) : <></>;
  //     // },
  //     // addToResponsables: (state, action) => {
  //     //   let teamResponsableExists = false;
  //     //   state.teams.responsables.map((team) => {
  //     //     if (team._id === action.payload._id) {
  //     //       console.log("teamResponsableExists");
  //     //       teamResponsableExists = true;
  //     //     }
  //     //   });
  //     //   console.log("teamResponsableExists", teamResponsableExists);
  //     //   !teamResponsableExists ? (
  //     //     state.teams.responsables.push(action.payload)
  //     //   ) : (
  //     //     <></>
  //     //   );
  //     // },
  //     // addToMember: (state, action) => {
  //     //   let teamMemberExists = false;
  //     //   state.teams.member.map((team) => {
  //     //     if (team._id === action.payload._id) {
  //     //       console.log("teamMemberExists");
  //     //       teamMemberExists = true;
  //     //     }
  //     //   });
  //     //   console.log("teamMemberExists", teamMemberExists);
  //     //   !teamMemberExists ? state.teams.member.push(action.payload) : <></>;
  //     // },
  //     // removeTeam: (state, action) => {
  //     //   console.log(action.payload);

  //     //   state.teams.managed = state.teams.managed.filter(
  //     //     (team) => team._id !== action.payload.deleted._id
  //     //   );

  //     //   state.teams.responsables = state.teams.responsables.filter(
  //     //     (team) => team._id !== action.payload.deleted._id
  //     //   );

  //     //   state.teams.member = state.teams.member.filter(
  //     //     (team) => team._id !== action.payload.deleted._id
  //     //   );
  //     // },
  //   },
  // }
);

export const {
  setTeams,
  addTeam,
  addToManaged,
  addToResponsables,
  addToMember,
  removeTeam,
} = teamsSlice.actions;

export default teamsSlice.reducer;
