import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";

const initialState = {
  entries: null,
};

const entrySlice = createSlice({
  name: "entries",
  initialState,
  reducers: {
    setEntries: (state, action) => {
      state.entries = action.payload;
    },
    addEntry: (state, action) => {
      console.log("addEntry", action.payload);
      console.log("state.entries", state.entries);
      state.entries.push(action.payload);
    },
    removeEntry: (state, action) => {
      state.entries = state.entries.filter(
        (entry) => entry.id !== action.payload
      );
    },
    modifyEntryById: (state, action) => {
      state.entries = state.entries.map((entry) => {
        // if (entry.id === action.payload.id) {
        //   return action.payload;
        // }
        // return entry;
        // if (entry._id === action.payload._id) {
        //   console.log("MODS entry", entry);
        //   console.log("MODS action.payload", action.payload);
        //   //  return action.payload;
        //   entry = action.payload;
        // }
        // return entry;
        if (entry._id === action.payload._id) {
          console.log("MODS entry", entry);
          console.log("MODS action.payload", action.payload);
          return action.payload; // retournez la nouvelle entrée
        }
        return entry; // retournez l'entrée non modifiée
      });
    },
  },
});

export const { setEntries, addEntry, removeEntry, modifyEntryById } =
  entrySlice.actions;

export default entrySlice.reducer;
