import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

const PrivateRoute = ({ component: Component }) => {
  const { isLoggedIn } = useSelector((state) => state.auth);

  return isLoggedIn === true ? <Component /> : <Navigate to="/auth" replace />;
};

export default PrivateRoute;
