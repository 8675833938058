import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";

const initialState = {
  graphConf: null,
};

const graphConfSlice = createSlice({
  name: "graphConf",
  initialState,
  reducers: {
    setGraphConf: (state, action) => {
      state.graphConf = action.payload;
    },
  },
});

export const { setGraphConf } = graphConfSlice.actions;

export default graphConfSlice.reducer;
