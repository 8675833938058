// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header h1 {
  font-size: 25px;
}
.header p {
  font-size: 18px;
}

#root {
  background-color: #fafafa;
}`, "",{"version":3,"sources":["webpack://./src/style/Global.scss"],"names":[],"mappings":"AAEE;EACE,eAAA;AADJ;AAIE;EACE,eAAA;AAFJ;;AAUA;EACE,yBAAA;AAPF","sourcesContent":[".header {\n  // margin: 5vh 5vw;\n  h1 {\n    font-size: 25px;\n  }\n\n  p {\n    font-size: 18px;\n  }\n}\n\n#app {\n  // background-color: #fafafa;\n}\n\n#root {\n  background-color: #fafafa;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
