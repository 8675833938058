import { useSelector } from "react-redux";
import { GetUser } from "../interceptor";

const EXPIRATION_DURATION = 1 * 60 * 60 * 1000; // 1 heures en millisecondes

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("reduxState");
    if (serializedState === null) {
      return undefined;
    }
    const persistedState = JSON.parse(serializedState);

    const currentTime = new Date().getTime();
    if (currentTime - persistedState.lastSaved > EXPIRATION_DURATION) {
      // console.log("EXPIRATION");
      return undefined; // L'état a expiré
    } else {
      // console.log("PAS EXPIRATION");
      return persistedState.state;
    }
  } catch (err) {
    console.error("Could not load state", err);
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    let tState = {
      state: state,
      lastSaved: new Date().getTime(),
    };
    state.auth.user && state.auth.user._id ? (
      (tState.id = state.auth.user._id)
    ) : (
      <></>
    );
    const serializedState = JSON.stringify(tState);
    localStorage.setItem("reduxState", serializedState);
  } catch (err) {
    console.error("Could not save state", err);
  }
};

export const clearState = () => {
  try {
    localStorage.removeItem("reduxState");
  } catch (err) {
    console.error("Could not clear state", err);
  }
};

export const logout = () => {
  return {
    type: "AUTH_LOGOUT",
  };
};

// export const loadState = () => {
//   try {
//     const serializedState = localStorage.getItem("reduxState");
//     if (serializedState === null) {
//       return undefined;
//     }
//     const persistedState = JSON.parse(serializedState);

//     const currentTime = new Date().getTime();
//     if (currentTime - persistedState.lastSaved > EXPIRATION_DURATION) {
//       console.log("EXPIRATION");
//       return undefined; // L'état a expiré
//     }

//     // return persistedState.state;
//     const user =  GetUser;
//     console.log("user", user);

//     if (
//       serializedState.auth.user._id == user._id ||
//       serializedState.id == user._id
//     ) {
//       console.log("ANCIENNE DATA: ");
//       return persistedState.state;
//     } else {
//       console.log("NOUVELLE DATA: ");
//       return undefined;
//     }

//     // return JSON.parse(serializedState);
//   } catch (err) {
//     return undefined;
//   }
// };
