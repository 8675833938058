import React, { Suspense, useRef, useState } from "react";
import Loading from "../components/Loading";
import Navbar from "../components/Navbar";
import { useSelector, useDispatch } from "react-redux";
import api from "../services/api";
import "../style/Profile.scss";
import { loginUser, setUser } from "../services/store/Slices/authSlice";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";

function Profile() {
  const { user, token } = useSelector((state) => state.auth); // Utilisez le sélecteur approprié pour extraire l'utilisateur
  const [firstname, setFirstName] = useState(user.firstName || "");
  const [lastname, setLastName] = useState(user.lastName || "");
  const [email, setEmail] = useState(user.email || "");
  const [birthday, setBirthday] = useState(
    ConvertTime(user.birthday) || undefined
  );
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [fnModif, setFnModif] = useState(false);
  const [lnModif, setLnModif] = useState(false);
  const [emailModif, setEmailModif] = useState(false);
  const [birthdayModif, setBirthdayModif] = useState(false);
  const [passwordModif, setPasswordModif] = useState(false);

  const toast = useRef(null);

  const dispatch = useDispatch();

  function ShowError(content) {
    toast.current.show({
      severity: "error",
      summary: "Erreur de connexion",
      detail: content,
      life: 6000,
    });
  }

  function ShowWarning(content) {
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: content,
      life: 6000,
    });
  }

  function ShowSuccess(content) {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: content,
      life: 6000,
    });
  }

  function reintStates() {
    setFnModif(false);
    setLnModif(false);
    setEmailModif(false);
    setBirthdayModif(false);
    setPasswordModif(false);
  }

  function ConvertTime(targetDate) {
    const date = new Date(targetDate);
    const initialDate = date.toISOString().substring(0, 10);
    return initialDate;
  }

  async function SaveModifications() {
    console.log("SaveModifications");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let moduser = {};
    fnModif ? (moduser.firstName = firstname) : <></>;
    lnModif ? (moduser.lastName = lastname) : <></>;
    emailModif ? (moduser.email = email) : <></>;
    birthdayModif ? (moduser.birthday = birthday) : <></>;
    if (
      oldPassword != "" &&
      newPassword != "" &&
      confirmNewPassword != "" &&
      newPassword === confirmNewPassword
    ) {
      moduser.oldPassword = oldPassword;
      moduser.password = newPassword;
    }

    try {
      const modif = await api.put("/users", moduser, { headers: headers });
      // console.log("modif", modif);
      reintStates();
      ShowSuccess("Modifications enregistrées");
      const newuser = modif.data.user;
      dispatch(setUser(newuser));
    } catch (error) {
      console.log("Erreur dans la modification du profil", error);
      ShowError(
        error.response.data.message || "Erreur dans la modification du profil"
      );
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // check modif password
    if (passwordModif) {
      if (newPassword === confirmNewPassword && newPassword != "") {
        SaveModifications();
      } else {
        ShowWarning("Les mots de passe ne correspondent pas");
      }
    } else {
      SaveModifications();
    }
  };

  return (
    <section className="fullPage" id="app">
      <Suspense fallback={Loading}>
        <header>
          <Navbar />
        </header>
        <main id="profile-page">
          <h1>Profile</h1>
          <section>
            <form onSubmit={handleSubmit}>
              <div className="flex-auto">
                <label className="font-bold block mb-2">Prénom</label>
                <InputText
                  value={firstname}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setFnModif(true);
                  }}
                  required
                />
              </div>

              <div>
                <label>Nom</label>
                <InputText
                  value={lastname}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setLnModif(true);
                  }}
                  required
                />
              </div>

              <div>
                <label>Email</label>
                <InputText
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailModif(true);
                  }}
                  required
                  disabled
                />
              </div>

              <div>
                <label>Date de naissance</label>
                <InputText
                  type="date"
                  value={ConvertTime(birthday)}
                  onChange={(e) => {
                    setBirthday(e.target.value);
                    setBirthdayModif(true);
                  }}
                  required
                />
              </div>
              <div className="change-pswd-blc">
                <label>Modifier Password ?</label>
                <Checkbox
                  onChange={(e) => setPasswordModif(e.checked)}
                  checked={passwordModif}
                />
              </div>
              {passwordModif && (
                <>
                  <label>
                    Ancien Password :
                    <Password
                      value={oldPassword}
                      onChange={(e) => {
                        setOldPassword(e.target.value);
                      }}
                      feedback={false}
                      toggleMask
                    />
                  </label>
                  {oldPassword && (
                    <>
                      <label>
                        Nouveau Password :
                        <Password
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          // feedback={false}
                          toggleMask
                        />
                      </label>
                      <label>
                        Confirmer Nouveau Password :
                        <Password
                          value={confirmNewPassword}
                          onChange={(e) =>
                            setConfirmNewPassword(e.target.value)
                          }
                          // feedback={false}
                          toggleMask
                        />
                      </label>
                    </>
                  )}
                </>
              )}
              <Button
                type="submit"
                label="Enregistrer"
                icon="pi pi-check"
                className="p-button-success"
              />
            </form>
            <Toast ref={toast} />
          </section>
        </main>
      </Suspense>
    </section>
  );
}

export default Profile;
