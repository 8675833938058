import React, { Suspense, useRef, useState } from "react";
import api from "../../services/api";
import Navbar from "../../components/Navbar";
import Loading from "../../components/Loading";
// import { logIn } from "../services/store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../services/store/Slices/authSlice";
import { store } from "../../services/store/store";
import { useNavigate } from "react-router-dom";
import { setEntries } from "../../services/store/Slices/entrySlice";
import { setGraphConf } from "../../services/store/Slices/graphConfSlice";
import { setTeams } from "../../services/store/Slices/teamsSlice";
import { setWorkConf } from "../../services/store/Slices/workconfSlice";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

function Login() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const toast = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Message Content",
      life: 3000,
    });
  };

  function ShowError(content) {
    toast.current.show({
      severity: "error",
      summary: "Erreur de connexion",
      detail: content,
      life: 6000,
    });
  }

  function ShowWarning(content) {
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: content,
      life: 6000,
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const resp = await api.post("/users/login", {
        email: email,
        password: password,
      });
      console.log(resp);

      if (resp.status === 200) {
        const user = resp.data.user;
        const token = resp.data.access_token;

        const entries = resp.data.entries;
        const workConfig = resp.data.workConf;
        const teams = resp.data.teams;
        const graphConf = resp.data.graphConf;

        // console.log("teams", resp.data.teams);
        // console.log("graphConf", resp.data.graphConf);
        // console.log("workConf", resp.data.workConf);

        dispatch(loginUser({ user, token }));
        dispatch(setEntries(entries));
        dispatch(setTeams(teams));
        dispatch(setWorkConf(workConfig));
        dispatch(setGraphConf(graphConf));

        // Affiche l'état d'authentification après l'inscription
        // const state = store.getState();
        // console.log("State after login", state);

        navigate("/dashboard"); // Naviguez vers le tableau de bord après la réussite de l'inscription
      } else {
        alert("Invalid login");
        ShowWarning("Invalid login");
      }
    } catch (error) {
      console.log("Erreur dans le login", error);
      error.response
        ? ShowError(error.response.data.message)
        : ShowError(error.message);
      // ShowError(error.response.data.message );
      // ShowError(error.message);
    }
  };

  return (
    <section>
      <Suspense fallback={Loading}>
        <header>
          <Navbar />
        </header>

        <main className="authPage" id="loginPage">
          <form onSubmit={handleSubmit}>
            {/* <label>
              Username:
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label> */}
            {/* <div className="card flex justify-content-center">
              <span className="p-float-label">
                <InputText
                  id="username2"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="username">Username</label>
              </span>
            </div> */}
            <span className="p-float-label">
              <InputText
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                className="p-inputtext-sm"
                placeholder="user@domain.com"
              />
              <label htmlFor="email">Email</label>
            </span>
            <span className="p-float-label">
              {/* <InputText
                id="password"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                className="p-inputtext-sm"
                placeholder="password"
              /> */}
              <Password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="p-inputtext-sm"
                placeholder="password"
                feedback={false}
                toggleMask
              />
              <label htmlFor="password">Password</label>
            </span>

            {/* <label>
              Password:
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label> */}
            {/* <input type="submit" value="Login" /> */}
            <Button
              label="Login"
              type="submit"
              value="Login"
              raised
              severity="secondary"
            />
          </form>
          <div id="nav-auth">
            Vous n'avez pas de compte ?{" "}
            <span onClick={() => navigate("/register")}>Créer un compte</span>
          </div>
          <Toast ref={toast} />
        </main>
      </Suspense>
    </section>
  );
}

export default Login;
