// import React, { useState } from "react";
// import { useSelector, useDispatch } from "react-redux";

// import {
//   AddMinutesToDate,
//   CalcTimeDiff,
//   FormatDateForInput,
// } from "../services/utils";
// import api from "../services/api";
// import {
//   modifyEntryById,
//   removeEntry,
// } from "../services/store/Slices/entrySlice";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";

// function EntryTableV2({ entry }) {
//   // const { user, token } = useSelector((state) => state.auth); // Utilisez le sélecteur approprié pour extraire l'utilisateur
//   const [entryToMod, setEntryToMod] = useState(undefined);
//   const [isEditable, setIsEditable] = useState(false);
//   const [modified, setModified] = useState(false);

//   const entries = useSelector((state) => state.entries.entries);

//   const dispatch = useDispatch();

//   const [formData, setFormData] = useState({
//     _id: entry._id,
//     teamId: entry.teamId,
//     date: FormatDateForInput(entry.date),
//     dateEnd: AddMinutesToDate(entry.date, entry.minutes),
//     description: entry.description,
//     isReclaimed: entry.isReclaimed,
//     minutes: entry.minutes,
//     archived: entry.archived ? entry.archived : null,
//   });

//   const handleInputChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     console.log("name", name);
//     console.log("value", value);
//     console.log("type", type);
//     console.log("checked", checked);

//     // Mettez à jour formData en fonction du type de champ de formulaire
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: type === "checkbox" ? checked : value,
//     }));

//     // Si formData est égal à l'entrée, alors setModified à false
//     formData === entry ? setModified(false) : setModified(true);
//   };

//   const toggleEditable = () => {
//     // console.log(!isEditable);

//     // si editable active, alors on delete les modifications avant de set en false
//     if (isEditable) {
//       setFormData({
//         _id: entry._id,
//         teamId: entry.teamId,
//         date: FormatDateForInput(entry.date),
//         dateEnd: AddMinutesToDate(entry.date, entry.minutes),
//         description: entry.description,
//         isReclaimed: entry.isReclaimed,
//         minutes: entry.minutes,
//       });
//       setModified(false);
//     }
//     setIsEditable(!isEditable);
//   };

//   async function SaveModifications() {
//     // console.log("ModifyEntry entryId", formData._id);
//     // console.log("ModifyEntry entryData", entry);
//     // console.log("ModifyEntry modifiyedData", formData);

//     // check Si formData est égal à l'entrée
//     if (formData === entry) {
//       console.log("formData === entry");
//       return;
//     } else {
//       // console.log("date", formData.date);
//       // console.log("dateEnd", formData.dateEnd);

//       const timeDiff = CalcTimeDiff(formData.date, formData.dateEnd);
//       // console.log("timeDiff", timeDiff);
//       // console.log("difInMinutes", difInMinutes);

//       const dateStartUTC = new Date(formData.date).toISOString();
//       const dateEndUTC = new Date(formData.dateEnd).toISOString();

//       const data = { _id: entry._id };

//       formData.teamId !== entry.teamId ? (
//         (data.teamId = formData.teamId)
//       ) : (
//         <></>
//       );
//       formData.date !== entry.date ? (data.date = dateStartUTC) : <></>;
//       timeDiff !== entry.minutes
//         ? (data.minutes = timeDiff)
//         : console.log("minutes pas modifié");
//       formData.description !== entry.description ? (
//         (data.description = formData.description)
//       ) : (
//         <></>
//       );
//       formData.isReclaimed !== entry.isReclaimed ? (
//         (data.isReclaimed = formData.isReclaimed)
//       ) : (
//         <></>
//       );

//       try {
//         const response = await api.patch(`/time-entries/${entry._id}`, data);
//         console.log("response PATCH", response);
//         dispatch(modifyEntryById(response.data));
//         reintStates();
//       } catch (error) {
//         if (error.response.data.message === "Entry not found") {
//           alert("L'entrée n'existe pas");
//           console.log(
//             "Erreur dans la modification de l'entrée : L'entrée n'existe pas",
//             error
//           );
//         } else {
//           alert("Erreur dans la suppression de l'entrée");
//           console.log("Erreur dans la modification de l'entrée", error);
//         }
//       }
//     }
//   }

//   function ConfirmDeleteEntry() {
//     console.log("ConfifmDeleteEntry activated");

//     // intégrer une confirmation par modal

//     DeleteEntry();
//   }

//   async function DeleteEntry() {
//     try {
//       const response = await api.delete(`/time-entries/${entry._id}`);
//       console.log("response DELETE", response);
//       dispatch(removeEntry(response.data));
//       reintStates();
//     } catch (error) {
//       if (error.response.data.message === "Entry not found") {
//         alert("L'entrée n'existe pas");
//         console.log(
//           "Erreur dans la suppression de l'entrée : L'entrée n'existe pas",
//           error
//         );
//       } else {
//         alert("Erreur dans la suppression de l'entrée");
//         console.log("Erreur dans la suppression de l'entrée", error);
//       }
//     }
//   }

//   function reintStates() {
//     setModified(false);
//     setIsEditable(false);
//   }

//   return (
//     <div key={entry._id}>
//       <form
//         key={entry._id}
//         onSubmit={(e) => {
//           e.preventDefault();
//           SaveModifications();
//         }}
//       >
//         <label>
//           _Id:
//           <input
//             type="text"
//             value={formData._id}
//             name="_id"
//             onChange={handleInputChange}
//             readOnly={!isEditable}
//             disabled={!isEditable}
//           />
//         </label>
//         <label>
//           TeamId:
//           <input
//             type="text"
//             name="teamId"
//             value={formData.teamId}
//             onChange={handleInputChange}
//             readOnly={!isEditable}
//             disabled={!isEditable}
//           />
//         </label>
//         <label>
//           Début:
//           {/* {formData.date} */}
//           <input
//             type="datetime-local"
//             value={formData.date}
//             onChange={handleInputChange}
//             name="date"
//             readOnly={!isEditable}
//             disabled={!isEditable}
//           />
//         </label>
//         <label>
//           Fin:
//           <input
//             type="datetime-local"
//             value={formData.dateEnd}
//             onChange={handleInputChange}
//             name="dateEnd"
//             readOnly={!isEditable}
//             disabled={!isEditable}
//           />
//         </label>
//         <label>
//           Description:
//           <input
//             type="text"
//             value={formData.description}
//             onChange={handleInputChange}
//             name="description"
//             readOnly={!isEditable}
//             disabled={!isEditable}
//           />
//         </label>
//         <label>
//           Reclaimed :
//           <input
//             type="checkbox"
//             value={formData.isReclaimed}
//             onChange={handleInputChange}
//             name="isReclaimed"
//             readOnly={!isEditable}
//             disabled={!isEditable}
//           />
//         </label>
//         {formData.archived && formData.archived !== null ? (
//           <label>
//             Archived :
//             <input
//               type="checkbox"
//               value={formData.archived}
//               onChange={handleInputChange}
//               name="archived"
//               readOnly={!isEditable}
//               disabled={!isEditable}
//             />
//           </label>
//         ) : (
//           <></>
//         )}
//         {modified ? (
//           <button onClick={SaveModifications}>Enregistrer</button>
//         ) : (
//           <></>
//         )}
//       </form>
//       <button onClick={toggleEditable}>
//         {isEditable ? "Annuler Édition" : "Mode Édition"}
//       </button>
//       {/* <button onClick={toggleEditable}>Save</button> */}
//       <button onClick={ConfirmDeleteEntry}>Delete</button>
//       <br /> <br />

//     </div>
//   );
// }

// export default EntryTableV2;

// // function formatDateForInput(dateString) {
// //   const date = new Date(dateString);
// //   const year = date.getFullYear();
// //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
// //   const day = date.getDate().toString().padStart(2, "0");
// //   const hours = date.getHours().toString().padStart(2, "0");
// //   const minutes = date.getMinutes().toString().padStart(2, "0");
// //   return `${year}-${month}-${day}T${hours}:${minutes}`;
// // }


// {/* <DataTable value={entries} tableStyle={{ minWidth: "50rem" }}>
//   <Column
//     field="userId"
//     header="User ID"
//     sortable
//     // style={{ width: "25%" }}
//   ></Column>
//   <Column
//     field="teamId"
//     header="Team ID"
//     sortable
//     // style={{ width: "25%" }}
//   >
//     caca
//   </Column>
//   <Column
//     field="date"
//     header="Date"
//     sortable
//     // style={{ width: "25%" }}
//   ></Column>
//   <Column
//     field="minutes"
//     header="Durée (minutes)"
//     sortable
//     // style={{ width: "25%" }}
//   ></Column>
//   <Column
//     field="description"
//     header="Description"
//     sortable
//     // style={{ width: "25%" }}
//   ></Column>
//   <Column
//     field="isReclaimed"
//     header="is Reclaimed"
//     sortable
//     // style={{ width: "25%" }}
//   ></Column>
//   <Column
//     field="reclaimedDate"
//     header="Reclaimed Date"
//     sortable
//     // style={{ width: "25%" }}
//   ></Column>
//   <Column
//     field="createdAt"
//     header="CreatedAt"
//     sortable
//     // style={{ width: "25%" }}
//   ></Column>
//   <Column
//     field="archived"
//     header="Archived"
//     sortable
//     // style={{ width: "25%" }}
//   ></Column>
// </DataTable>; */}