// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stats-cards {
  width: 20vw;
  min-width: 100px;
  max-width: 300px;
}
.stats-cards .p-card-header {
  text-align: center;
  font-weight: 300;
  background-color: rgba(128, 128, 128, 0.0784313725);
  border-radius: 8px 8px 0 0;
}
.stats-cards .p-card-body {
  padding: 1.25rem 1.25rem 0.25rem 1.25rem;
}
.stats-cards .p-card-body .p-card-title {
  text-align: center;
  font-size: 30px;
  margin-bottom: 5px;
}
.stats-cards .p-card-body .p-card-content {
  text-align: right;
  padding: 0;
}
.stats-cards .p-card-body .p-card-content p {
  font-size: 12px;
  font-style: italic;
  margin: 0;
  font-weight: 300;
}
.stats-cards .p-card-body .p-card-footer {
  text-align: center;
  font-weight: 600;
  padding-top: 0;
  font-size: 18px;
}
.stats-cards .p-card-body .p-card-footer p {
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/style/StatsCards.scss"],"names":[],"mappings":"AAAA;EAEE,WAAA;EACA,gBAAA;EACA,gBAAA;AAAF;AAEE;EACE,kBAAA;EACA,gBAAA;EACA,mDAAA;EACA,0BAAA;AAAJ;AAGE;EACE,wCAAA;AADJ;AAEI;EACE,kBAAA;EACA,eAAA;EACA,kBAAA;AAAN;AAGI;EACE,iBAAA;EACA,UAAA;AADN;AAEM;EACE,eAAA;EACA,kBAAA;EACA,SAAA;EACA,gBAAA;AAAR;AAII;EACE,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;AAFN;AAIM;EACE,kBAAA;AAFR","sourcesContent":[".stats-cards {\n  //   width: 200px;\n  width: 20vw;\n  min-width: 100px;\n  max-width: 300px;\n\n  .p-card-header {\n    text-align: center;\n    font-weight: 300;\n    background-color: #80808014;\n    border-radius: 8px 8px 0 0;\n  }\n\n  .p-card-body {\n    padding: 1.25rem 1.25rem 0.25rem 1.25rem;\n    .p-card-title {\n      text-align: center;\n      font-size: 30px;\n      margin-bottom: 5px;\n    }\n\n    .p-card-content {\n      text-align: right;\n      padding: 0;\n      p {\n        font-size: 12px;\n        font-style: italic;\n        margin: 0;\n        font-weight: 300;\n      }\n    }\n\n    .p-card-footer {\n      text-align: center;\n      font-weight: 600;\n      padding-top: 0;\n      font-size: 18px;\n\n      p {\n        margin-bottom: 5px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
