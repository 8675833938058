import React, { Suspense, useState } from "react";
import Loading from "../components/Loading";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";
import { ConvertTime } from "../services/utils";
import EntryTable from "../components/EntryTable";
import NewTeam from "../components/ToolBox/NewTeam";
import TeamTable from "../components/TeamTable";
import TeamTable_v2 from "../components/TeamTable_v2";

export default function Teams() {
  const user = useSelector((state) => state.auth.user); // Utilisez le sélecteur approprié pour extraire l'utilisateur
  const entries = useSelector((state) => state.entries.entries);
  const teams = useSelector((state) => state.teams.teams);

  const [showNewTeam, setShowNewTeam] = useState(false);

  console.log(teams);

  return (
    <section className="fullPage" id="app">
      <Suspense fallback={Loading}>
        <header>
          <Navbar />
        </header>
        <main>
          <section className="header">
            <h1>TEAMS</h1>
            <p>
              Bonjour {user.firstName} {user.lastName}
            </p>
          </section>

          <section>
            <h2>ToolBox</h2>
            <div className="tools-bloc">
              <button
                onClick={(e) => {
                  setShowNewTeam(!showNewTeam);
                }}
              >
                New Team
              </button>
            </div>
            {showNewTeam ? <NewTeam /> : <></>}
          </section>

          <section>
            {/* {teams.managed.length > 0 ? (
              <div>
                <h3>Vos Teams (manager) :</h3>
                {teams.managed.map((team) => (
                  <TeamTable
                    key={"managed" + team._id + team.title}
                    team={team}
                  />
                ))}
              </div>
            ) : (
              <></>
            )} */}

            {teams.managed.length > 0 && (
              <TeamTable_v2 teams={teams.managed} title="manager" />
            )}

            {teams.responsables.length > 0 ? (
              <div>
                <h3>Vos Teams (responsable(s)) :</h3>
                {teams.responsables.map((team) => (
                  <p key={"responsables" + team.id + team.title}>
                    {team.title}
                    <button>modify</button>
                    <button>delete</button>
                  </p>
                ))}
              </div>
            ) : (
              <></>
            )}
            {teams.member.length > 0 ? (
              <div>
                <h3>Vos Teams (member) :</h3>
                {teams.member.map((team) => (
                  <p key={"member" + team.id + team.title}>
                    {team._id} - {team.title} - {team.title} - {team.managerId}{" "}
                    - {team.responsablesId} - {team.peopleId} -{" "}
                    {team.pendingIds}
                  </p>
                ))}
              </div>
            ) : (
              <></>
            )}
          </section>
        </main>
      </Suspense>
    </section>
  );
}
