import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { store } from "../../services/store/store";
import { selectUser } from "../../services/store/Slices/authSlice";
import Navbar from "../../components/Navbar";
import Loading from "../../components/Loading";
import NewWT from "../../components/ToolBox/NewTE";
import ModifHS from "../../components/ToolBox/ModifHS";
import DeleteHS from "../../components/ToolBox/DeleteHS";
import StatsCard from "../../components/StatsCard";
import "../../style/Dashboard.scss";

export default function Dashboard() {
  const user = useSelector((state) => state.auth.user); // Utilisez le sélecteur approprié pour extraire l'utilisateur
  const entries = useSelector((state) => state.entries.entries);
  const workConf = useSelector((state) => state.workConf.workConf);
  const teams = useSelector((state) => state.teams.teams);

  const [showNewWT, setShowNewWT] = useState(false);
  const [showModifStatusHS, setShowModifStatusHS] = useState(false);
  const [showDeleteHS, setShowDeleteHS] = useState(false);

  const totalMinutes = entries.reduce((acc, entry) => {
    return acc + entry.minutes;
  }, 0);

  const totalMinutesReclaimed = entries.reduce((acc, entry) => {
    return entry.isReclaimed ? acc + entry.minutes : acc;
  }, 0);

  const totalMinutesThisMonth = entries.reduce((acc, entry) => {
    const entryDate = new Date(entry.date);
    const today = new Date();
    const thisMonth = today.getMonth();
    const thisYear = today.getFullYear();
    const entryMonth = entryDate.getMonth();
    const entryYear = entryDate.getFullYear();
    if (entryMonth === thisMonth && entryYear === thisYear) {
      return acc + entry.minutes;
    } else {
      return acc;
    }
  }, 0);

  const totalMinutesReclaimedThisMonth = entries.reduce((acc, entry) => {
    const entryDate = new Date(entry.date);
    const today = new Date();
    const thisMonth = today.getMonth();
    const thisYear = today.getFullYear();
    const entryMonth = entryDate.getMonth();
    const entryYear = entryDate.getFullYear();
    if (entryMonth === thisMonth && entryYear === thisYear) {
      return entry.isReclaimed ? acc + entry.minutes : acc;
    } else {
      return acc;
    }
  }, 0);

  useEffect(() => {
    console.log("user", user);

    console.log("entries", entries);
    console.log("workConf", workConf);
    console.log("teams", teams);
  }, []);

  // const state = store.getState();
  // console.log("State after login", state);

  return (
    <section className="fullPage" id="app">
      <Suspense fallback={Loading}>
        <header>
          <Navbar />
        </header>
        <main>
          <section className="header">
            <h1>Mes stats principales</h1>
            <p>
              Bonjour {user.firstName} {user.lastName}
            </p>
          </section>

          {/* <section>
            <h2>ToolBox</h2>
            <div className="tools-bloc">
              <button
                onClick={(e) => {
                  setShowNewWT(!showNewWT);
                }}
              >
                New Heure Supp
              </button>
              <button
                onClick={() => {
                  setShowModifStatusHS(!showModifStatusHS);
                }}
              >
                Modifier status HS
              </button>
              <button
                onClick={() => {
                  setShowDeleteHS(!showDeleteHS);
                }}
              >
                Supprimer HS
              </button>
            </div>
            {showNewWT ? <NewWT /> : <></>}
            {showModifStatusHS ? <ModifHS /> : <></>}
            {showDeleteHS ? <DeleteHS /> : <></>}
          </section> */}
          {/* <section><h2>Mes stats principales</h2></section> */}
          <section className="hs-sec">
            {/* <h3>Mes Heures</h3> */}
            <h2>Mes Heures</h2>

            <div className="stats-hs-blc">
              <StatsCard
                cat={"Mois actuel"}
                title="Total"
                sub={"Minutes"}
                value={totalMinutesThisMonth}
              />
              <StatsCard
                cat={"Mois actuel"}
                title="Récupérées"
                sub={"Minutes"}
                value={totalMinutesReclaimedThisMonth}
              />
              <StatsCard
                cat={"Mois actuel"}
                title="Non récupérées"
                sub={"Minutes"}
                value={totalMinutesThisMonth - totalMinutesReclaimedThisMonth}
              />
            </div>
            <div className="stats-hs-blc">
              <StatsCard
                cat={"Global"}
                title="Total"
                sub={"Minutes"}
                value={totalMinutes}
              />
              <StatsCard
                cat={"Global"}
                title="Récupérées"
                sub={"Minutes"}
                value={totalMinutesReclaimed}
              />
              <StatsCard
                cat={"Global"}
                title="Non récupérées"
                sub={"Minutes"}
                value={totalMinutes - totalMinutesReclaimed}
              />
            </div>
            {/* <div>
              <p>filtrage box</p>
            </div> */}
            {/* <div className="graph-hs-blc">
              {entries.map((entry) => (
                <div key={entry._id + entry.title}>
                  {entry.teamId} - {entry.date} - {entry.description} -{" "}
                  {entry.isReclaimed.toString()} - {entry.minutes}
                </div>
              ))}
            </div> */}
          </section>

          {/* <section className="teams-hs-sec">
            <h3>Mes Teams</h3>
            <div>
              {teams.managed.length > 0 ? (
                <div>
                  <h3>Vos Teams (manager) :</h3>
                  {teams.managed.map((team) => (
                    <p key={team._id + team.title}>
                      {team.title} - {team._id}
                    </p>
                  ))}
                </div>
              ) : (
                <></>
              )}
              {teams.responsables.length > 0 ? (
                <div>
                  <h3>Vos Teams (responsable(s)) :</h3>
                  {teams.responsables.map((team) => (
                    <p key={team._id + team.title}>{team.title}</p>
                  ))}
                </div>
              ) : (
                <></>
              )}
              {teams.member.length > 0 ? (
                <div>
                  <h3>Vos Teams (member) :</h3>
                  {teams.member.map((team) => (
                    <p key={team._id + team.title}>{team.title}</p>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </section> */}

          {/* <section>
            <h2>Mes Works actifs</h2>
            {workConf.map((entry) => (
              <div key={entry._id}>
                {entry.teamIds} - {entry.title} - {entry.daysWeek} -{" "}
                {entry.weekHours} - {entry.options} -{" "}
              </div>
            ))}
          </section> */}
        </main>
      </Suspense>
    </section>
  );
}
