import React, { Suspense } from "react";
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";
import { useSelector } from "react-redux";
import WorkTable from "../components/WorkTable";

function Works() {
  const { workConf } = useSelector((state) => state.workConf); // Utilisez le sélecteur approprié pour extraire l'utilisateur
  //   const entries = useSelector((state) => state.entries.entries);

  console.log("workConf", workConf);
  return (
    <section className="fullPage" id="app">
      <Suspense fallback={Loading}>
        <header>
          <Navbar />
        </header>
        <main>
          <h1>Works</h1>
          <section>
            Panneau des actions : nouvelle works / modify works / Archive works
          </section>
          <div>Liste des works existants (modifiables directement)</div>
          <section>
            <h2></h2>
            <div>
              {/* {workConf.map((team) => (
                <div key={"works" + team._id + team.title}>
                  {team.title} | {team.dateFrom} | {team.daysWeek} |{" "}
                  {team.teamIds} | {team.data} | {team.options} |{" "}
                  {team.weekHours} | {team.userId} | {team.updatedAt} |
                  <br />
                  <br />
                </div>
              ))} */}

              {workConf.map((work) => (
                <WorkTable key={work._id} work={work} />
              ))}
            </div>
          </section>
        </main>
      </Suspense>
    </section>
  );
}

export default Works;
