import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { clearState } from "../storageServices";

// export const registerUser = createAsyncThunk(
//   "auth/register",
//   async (user, thunkAPI) => {
//     try {
//       const response = await api.post("/users/register", user);
//       return response.data;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response.data);
//     }
//   }
// );

// export const logUser = createAsyncThunk(
//   "auth/login",
//   async (userInfo, thunkAPI) => {
//     try {
//       const response = await api.post("/users/login", userInfo);

//       console.log("Response from logUser", response.data);
//       return response.data;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response.data);
//     }
//   }
// );

const initialState = {
  isLoggedIn: false,
  isAdmin: false,
  user: null,
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    loginUser(state, action) {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      const admin = action.payload.user.isAdmin;

      action.payload.user.isAdmin ? (state.isAdmin = true) : <></>;
    },
    logOut(state) {
      state.user = null;
      state.isLoggedIn = false;
      state.token = null;
      state.isAdmin = false;
    },
    checkUserAuthentication(state) {
      // Ajoutez votre logique pour vérifier l'authentification de l'utilisateur ici
      // Pour cet exemple, nous allons simplement définir isLoggedIn sur false
      state.isLoggedIn = false;
    },
  },
});

export const { setUser, logOut, checkUserAuthentication, loginUser } =
  authSlice.actions;

export default authSlice.reducer;
