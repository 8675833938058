import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { useDispatch, useSelector } from "react-redux";
import api from "../services/api";
import {
  AddMinutesToDate,
  CalcTimeDiff,
  FormatDateForInput,
} from "../services/utils";
import {
  modifyEntryById,
  removeEntry,
} from "../services/store/Slices/entrySlice";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";

// export default function EntryTableV5() {
//   const [entries, setEntries] = useState(
//     useSelector((state) => state.entries.entries)
//   );

//   const [statuses] = useState(["INSTOCK", "LOWSTOCK", "OUTOFSTOCK"]);

//   const dispatch = useDispatch();

//   function ConfirmDeleteEntry() {
//     console.log("ConfifmDeleteEntry activated");

//     // intégrer une confirmation par modal

//     DeleteEntry();
//   }

//   const onRowEditComplete = async (e) => {
//     const updatedEntry = e.data;
//     console.log("updatedEntry", updatedEntry);
//     console.log("ON DEVRAIT SAVE LA");
//     try {
//       //   const response = await api.patch(
//       //     `/time-entries/${updatedEntry._id}`,
//       //     updatedEntry
//       //   );
//       //   console.log("response PATCH", response);
//       // Si nécessaire, mettez à jour le store Redux ou effectuez d'autres actions ici
//     } catch (error) {
//       console.error("Erreur lors de la mise à jour de l'entrée", error);
//     }
//   };

//   const textEditor = (props) => {
//     return (
//       <InputText
//         type="text"
//         value={props.rowData[props.field]}
//         onChange={(e) => {
//           const updatedEntries = [...entries];
//           updatedEntries[props.rowIndex][props.field] = e.target.value;
//           setEntries(updatedEntries);
//         }}
//       />
//     );
//   };
//   const minutesEditor = (props) => {
//     return (
//       <InputNumber
//         type="text"
//         value={props.rowData[props.field]}
//         onChange={(e) => {
//           const updatedEntries = [...entries];
//           updatedEntries[props.rowIndex][props.field] = e.value; // Notez que c'est e.value pour InputNumber
//           setEntries(updatedEntries);
//         }}
//       />
//     );
//   };
//   const reclamedData = (props) => {
//     return (
//       <Checkbox
//         onChange={(e) => {}}
//         checked={props.isReclaimed}
//         disabled
//       ></Checkbox>
//     );
//   };
//   const ReclamedEditor = (props) => {
//     console.log("ReclamedEditor props", props);
//     let matching = entries.find((element) => element._id === props.rowData._id);
//     console.log("matching", matching);
//     return (
//       <Checkbox
//         onChange={(e) => {
//           // Initial value
//           console.log("props", props);
//           console.log("props.rowData.isReclaimed", props.rowData.isReclaimed);

//           // Click value
//           console.log("e", e);
//           console.log("e.chceked", e.checked);
//         }}
//         checked={matching.isReclaimed}
//       />
//     );
//   };
//   async function DeleteEntry(entry) {
//     // try {
//     //   const response = await api.delete(`/time-entries/${entry._id}`);
//     //   console.log("response DELETE", response);
//     //   dispatch(removeEntry(response.data));
//     //   reintStates();
//     // } catch (error) {
//     //   if (error.response.data.message === "Entry not found") {
//     //     alert("L'entrée n'existe pas");
//     //     console.log(
//     //       "Erreur dans la suppression de l'entrée : L'entrée n'existe pas",
//     //       error
//     //     );
//     //   } else {
//     //     alert("Erreur dans la suppression de l'entrée");
//     //     console.log("Erreur dans la suppression de l'entrée", error);
//     //   }
//     // }
//   }
//   return (
//     <div className="card p-fluid">
//       <DataTable
//         value={entries}
//         editMode="row"
//         dataKey="_id"
//         onRowEditComplete={onRowEditComplete}
//       >
//         <Column field="_id" header="_Id" editor={textEditor}></Column>
//         <Column field="teamId" header="TeamId" editor={textEditor}></Column>
//         <Column field="date" header="Début" editor={textEditor}></Column>
//         <Column
//           field="minutes"
//           header="Minutes"
//           editor={minutesEditor}
//         ></Column>
//         <Column
//           field="description"
//           header="Description"
//           editor={textEditor}
//         ></Column>
//         <Column
//           field="isReclaimed"
//           header="Reclaimed"
//           editor={ReclamedEditor}
//           body={reclamedData}
//         ></Column>
//         <Column rowEditor></Column>
//       </DataTable>
//     </div>
//   );
// }

export const EntryTableV5 = ({ entries }) => {
  const [data, setData] = useState(entries);

  const onInputChange = (e, props, field) => {
    let updatedData = [...data];
    updatedData[props.rowIndex][field] = e.target.value;
    setData(updatedData);
  };

  const onCheckboxChange = (e, props) => {
    let updatedData = [...data];
    updatedData[props.rowIndex]["isReclaimed"] = e.checked;
    setData(updatedData);
  };

  const textEditor = (props, field) => {
    return (
      <InputText
        type="text"
        value={props.rowData[field]}
        onChange={(e) => onInputChange(e, props, field)}
      />
    );
  };

  const minutesEditor = (props) => {
    return textEditor(props, "minutes");
  };

  const ReclamedEditor = (props) => {
    return (
      <Checkbox
        checked={props.rowData["isReclaimed"]}
        onChange={(e) => onCheckboxChange(e, props)}
      />
    );
  };

  const onRowEditComplete = (event) => {
    // Handle the logic to save the edited data, e.g., API call
    console.log("Row edited", event.data);
  };

  const reclamedData = (rowData) => {
    return rowData.isReclaimed ? "Yes" : "No";
  };

  return (
    <div className="card p-fluid">
      <DataTable
        value={data}
        editMode="row"
        dataKey="_id"
        onRowEditComplete={onRowEditComplete}
      >
        <Column
          field="_id"
          header="_Id"
          editor={(props) => textEditor(props, "_id")}
        ></Column>
        <Column
          field="teamId"
          header="TeamId"
          editor={(props) => textEditor(props, "teamId")}
        ></Column>
        <Column
          field="date"
          header="Début"
          editor={(props) => textEditor(props, "date")}
        ></Column>
        <Column
          field="minutes"
          header="Minutes"
          editor={minutesEditor}
        ></Column>
        <Column
          field="description"
          header="Description"
          editor={(props) => textEditor(props, "description")}
        ></Column>
        <Column
          field="isReclaimed"
          header="Reclaimed"
          editor={ReclamedEditor}
          body={reclamedData}
        ></Column>
        <Column rowEditor></Column>
      </DataTable>
    </div>
  );
};
