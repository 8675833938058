import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "../../services/api";
import { addEntry, setEntries } from "../../services/store/Slices/entrySlice";
import { CalcTimeDiff } from "../../services/utils";

function NewWT() {
  const user = useSelector((state) => state.auth.user); // Utilisez le sélecteur approprié pour extraire l'utilisateur
  const token = useSelector((state) => state.auth.token); // Utilisez le sélecteur approprié pour extraire l'utilisateur
  const teams = useSelector((state) => state.teams.teams);

  // const [teamId, setTeamId] = useState("");
  const [teamId, setTeamId] = useState(
    teams.managed.filter((team) => team.title === "Default Team")[0]
  );
  const [date, setDate] = useState(undefined);
  const [minutes, setMinutes] = useState(0);
  const [description, setDescription] = useState("");
  const [reclamed, setReclamed] = useState(false);
  const [dateStart, setDateStart] = useState(undefined);
  const [dateEnd, setDateEnd] = useState(undefined);
  const [differenceInMinutes, setDifferenceInMinutes] = useState(0);
  const [differenceInHours, setDifferenceInHours] = useState(0);

  const dispatch = useDispatch();

  const [options, setOptions] = useState([
    teams.managed.map((team) => {
      return (
        <option key={team._id} value={team._id}>
          {team.title}
        </option>
      );
    }),
  ]);

  // set a variable to hold the team data if team.title === "Default Team" for each team in teams.managed
  const defaultTeam = teams.managed.filter(
    (team) => team.title === "Default Team"
  )[0];

  function CalcTime() {
    // Convertissez les chaînes en objets Date
    const datetime1 = new Date(dateStart);
    const datetime2 = new Date(dateEnd);

    const differenceInMilliseconds = datetime2 - datetime1;
    const difInMinutes = differenceInMilliseconds / (1000 * 60);
    const difInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));

    const difInHoursRest = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / 1000
    );
    const minutesSupplementaires = Math.floor(difInHoursRest / 60);

    // console.log(
    //   `La différence entre les deux dates est de ${differenceInMinutes} minutes.`
    // );

    // console.log(
    //   `La différence entre les deux dates est de ${difInHoursRest} heures et ${minutesSupplementaires} minutes.`
    // );
    // console.log("dateStart", dateStart);
    setDate(dateStart);
    setMinutes(difInMinutes);
    setDifferenceInHours(difInHours);
    setDifferenceInMinutes(minutesSupplementaires);
  }

  async function PostNewEntry() {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = {
      teamId: teamId,
      date: date,
      minutes: minutes,
      description: description,
      reclamed: reclamed,
    };
    try {
      const response = await api.post("/entries", data, {
        headers: headers,
      });
      console.log("response", response);
    } catch (error) {
      console.log("Erreur dans la création de l'entrée", error);
    }
  }

  // function CalcTimeDiff(start, end) {
  //   const datetime1 = new Date(dateStart);
  //   const datetime2 = new Date(dateEnd);

  //   const differenceInMilliseconds = datetime2 - datetime1;
  //   const difInMinutes = differenceInMilliseconds / (1000 * 60);
  //   const difInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));

  //   const difInHoursRest = Math.floor(
  //     (differenceInMilliseconds % (1000 * 60 * 60)) / 1000
  //   );
  //   const minutesSupplementaires = Math.floor(difInHoursRest / 60);
  //   // console.log(
  //   //   `La différence entre les deux dates est de ${differenceInHours} heures et ${minutesSupplementaires} minutes.`
  //   // );

  //   return difInMinutes;
  // }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("NewWT handleSubmit");

    const timeDiff = CalcTimeDiff(dateStart, dateEnd);

    const dateStartUTC = new Date(dateStart).toISOString();
    const dateEndUTC = new Date(dateEnd).toISOString();

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = {
      teamId: teamId,
      date: dateStartUTC,
      minutes: timeDiff,
      description: description,
      isReclaimed: reclamed,
    };
    try {
      const response = await api.post("/time-entries", data, {
        headers: headers,
      });
      console.log("response", response);
      dispatch(addEntry(response.data));
    } catch (error) {
      console.log("Erreur dans la création de l'entrée", error);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          TeamId:
          {/* <input
            type="text"
            value={teamId}
            onChange={(e) => setTeamId(e.target.value)}
            required
          /> */}
          <select
            onChange={(e) => {
              console.log(e.target.value);
              setTeamId(e.target.value);
            }}
            selected={true}
          >
            {options}
          </select>
        </label>

        <label>
          Date et Heure Début:
          <input
            type="datetime-local"
            value={dateStart}
            onChange={(e) => setDateStart(e.target.value)}
          />
        </label>
        <label>
          Date et Heure FIN:
          <input
            type="datetime-local"
            value={dateEnd}
            onChange={(e) => setDateEnd(e.target.value)}
          />
        </label>
        <label>
          Description:
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </label>
        <label>
          Reclamed :
          <input
            type="checkbox"
            value={reclamed}
            onChange={(e) => setReclamed(!reclamed)}
          />
        </label>
        <button type="submit">Enregistrer</button>
      </form>
    </div>
  );
}

export default NewWT;
