import React, { useState, Suspense, useRef } from "react";
import api from "../../services/api";
import Loading from "../../components/Loading";
import Navbar from "../../components/Navbar";
import "../../style/auth.scss";
import { useDispatch } from "react-redux";
import { loginUser, registerUser } from "../../services/store/Slices/authSlice";
import { setEntries } from "../../services/store/Slices/entrySlice";
import { useNavigate } from "react-router-dom";
import { setTeams } from "../../services/store/Slices/teamsSlice";
import { setWorkConf } from "../../services/store/Slices/workconfSlice";
import { setGraphConf } from "../../services/store/Slices/graphConfSlice";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { ConvertTime } from "../../services/utils";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

function Register() {
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");
  // const [birthday, setBirthday] = useState(
  //   ConvertTime(user.birthday) || undefined
  // );
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef(null);

  function ShowError(content) {
    toast.current.show({
      severity: "error",
      summary: "Erreur de connexion",
      detail: content,
      life: 6000,
    });
  }

  function ShowWarning(content) {
    Toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: content,
      life: 6000,
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const user = {
      firstName: firstName,
      lastName: lastName,
      birthday: birthday,
      email: email,
      password: password,
    };

    try {
      const resp = await api.post("/users/register", user);
      // setIsRegistered = true;
      // dispatch(registerUser({ username, password }));
      if (resp.status === 201) {
        const user = resp.data.user;
        const token = resp.data.access_token;

        const entries = resp.data.entries;
        const workConfig = resp.data.workConf;
        const teams = resp.data.teams;
        const graphConf = resp.data.graphConf;

        console.log("resp.data", resp.data);

        dispatch(loginUser({ user, token }));
        dispatch(setEntries(entries));
        dispatch(setTeams(teams));
        dispatch(setWorkConf(workConfig));
        dispatch(setGraphConf(graphConf));

        navigate("/dashboard"); // Naviguez vers le tableau de bord après la réussite de l'inscription
      } else {
        alert("Invalid login");
        ShowWarning("Invalid login");
      }
    } catch (error) {
      console.log("Erreur dans l'inscription", error);
      ShowError(error.response.data.message);
      // alert(error);
    }
  };

  return (
    <>
      <Suspense fallback={Loading}>
        <header>
          <Navbar />
        </header>
        <main className="authPage" id="registerPage">
          <form onSubmit={handleSubmit}>
            {/* <label>
              Prénom:
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </label> */}
            <span className="p-float-label">
              <InputText
                id="fisrtname"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                className="p-inputtext-sm"
                placeholder="Jean"
                required
              />
              <label htmlFor="fisrtname">Prénom</label>
            </span>
            {/* <label>
              Nom:
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </label> */}
            <span className="p-float-label">
              <InputText
                id="nom"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                className="p-inputtext-sm"
                placeholder="Dupont"
                required
              />
              <label htmlFor="lastName">Nom</label>
            </span>
            <span className="p-float-label">
              <InputText
                type="date"
                value={birthday}
                onChange={(e) => {
                  setBirthday(e.target.value);
                  // setBirthdayModif(true);
                }}
                required
              />
              {/* <label>Date de naissance:</label> */}
            </span>
            <span className="p-float-label">
              <InputText
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                className="p-inputtext-sm"
                placeholder="user@domain.com"
                required
              />
              <label htmlFor="email">Email</label>
            </span>
            <span className="p-float-label">
              <Password
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="p-inputtext-sm"
                placeholder="password"
                // feedback={false}
                toggleMask
                required
              />
              <label htmlFor="password">Password</label>
            </span>
            {/* <input type="submit" value="Register" /> */}
            <Button
              label="S'enregistrer"
              type="submit"
              value="Register"
              severity="secondary"
              icon="pi pi-check"
              raised
            />
          </form>
          <div id="nav-auth">
            Vous avez déjà un compte ?{" "}
            <span onClick={() => navigate("/")}>Connectez-vous</span>
          </div>
          <Toast ref={toast} />
        </main>
      </Suspense>
    </>
  );
}

export default Register;
