import axios from "axios";
import { reactUrl } from "../config";
import { useSelector, useDispatch } from "react-redux";

// Créez une instance d'axios
const api = axios.create({
  // Mettez ici l'URL de base de votre API
  baseURL: `${reactUrl}/api`,

  // Vous pouvez ajouter des en-têtes globaux ici, par exemple un en-tête d'autorisation

  headers: {
    "Content-Type": "application/json",
    // 'Authorization': 'Bearer my-token'
  },

  // Vous pouvez ajouter d'autres configurations globales ici
});

export default api;

// const { token } = useSelector((state) => state.auth); // Utilisez le sélecteur approprié pour extraire l'utilisateur

// export const headers = {
//   Authorization: `Bearer ${token}`,
// };
